@font-face {
    font-family: SourceBold;
    src: url("../../../assets/fonts/SourceSansProBold.ttf");
    font-weight: bold;
  }
@font-face {
    font-family: Source;
    src: url("../../../assets/fonts/SourceSansProRegular.ttf");
  }

.detailsContainer h3, .detailsContainer h4{
    font-family: "SourceBold";
    color:black !important;
}
 .detailsContainer h4{
    font-size: 20px;
    font-family: "SourceBold";
    color:black !important;
}
.detailsContainer p{
    letter-spacing: 1px;
    color:black !important;
}

.detailsContainer.userDetails{
    width: 25%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 0 25px 25px 0;
    position: sticky;
    top: -1px;
    font-family: 'Source';
}


.detailsContainer .profileInfo.card{
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 35vh;
    width: 100%;
    max-width: unset !important;
    border-radius: 25px;
    min-width: 300px;
    min-height: 250px;
    box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px 4px;
}
.profileInfo .personalInfo{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}
.personalInfo .profilePic{
    width: 100px;
    height: 100px;
    border-radius:50%;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
}
.detailsContainer .card.level{
    padding: 15px 20px 15px 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 22vh;
    width: 100%;
    max-width: unset !important;
    border-radius: 25px;
    min-width: 300px;
    box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px 4px;
    min-height: unset;
    margin: 15px 15px;
    border: transparent;

}



.detailsContainer .card.level.bronze{
    background-color: var(--black-wolly);
}

.detailsContainer .card.level.bronze .levelUpButton, .detailsContainer .card.level.plata .levelUpButton{
    border-radius: 10px;
    margin-top: 15px;
    padding: 5px 18px;
    font-size: 15px;
    cursor: pointer;
}
.detailsContainer .card.level.bronze .levelUpButton{
    border: solid var(--new-gold) 2px;
    color: var(--new-gold);
}
.detailsContainer .card.level.plata .levelUpButton{
    border: solid var(--black-wolly) 2px;
    color: var(--black-wolly);
}
.detailsContainer .card.level.platino{
    background: linear-gradient(31deg, #000000, #000, #ffffff, #000, #000);

    background-size: 100% 800%;

    -webkit-animation: AnimationName 4s ease infinite;
    -moz-animation: AnimationName 4s ease infinite;
    animation: AnimationName 4s ease infinite;
}
.detailsContainer .card.level.bronze p, .detailsContainer .card.level.platino p{
    color: var(--white) !important;
    text-align: center;
    font-size: 12px;
    margin-bottom: 0;
}
.detailsContainer .card.level .levelText{
    font-size: 30px !important;
    font-family: "SourceBold";
    line-height: 35px;
}
.detailsContainer .card.level.plata p, .detailsContainer .card.level.oro p{
    color: var(--black-wolly) !important;
    text-align: center;
}
.detailsContainer .card.level.plata{
    background-color: var(--black-wolly);
    background: linear-gradient(31deg, #7f7f7f, #f5f5f5, #949494, #ededee, #848484);

    background-size: 100% 800%;

    -webkit-animation: AnimationName 4s ease infinite;
    -moz-animation: AnimationName 4s ease infinite;
    animation: AnimationName 4s ease infinite;
}
.detailsContainer .card.level.oro{
    background-color: var(--black-wolly);
    background: linear-gradient(31deg, #bf953f, #fcf6ba, #b38728, #fbf5b7, #aa771c);
    background-size: 1000% 1000%;

    -webkit-animation: AnimationName 6s ease infinite;
    -moz-animation: AnimationName 6s ease infinite;
    animation: AnimationName 6s ease infinite;
}
.detailsContainer .card.level img{
    width: 90px;
    height: 90px;
}
@-webkit-keyframes AnimationName {
    0%{background-position:57% 0%}
    50%{background-position:44% 100%}
    100%{background-position:57% 0%}
}
@-moz-keyframes AnimationName {
    0%{background-position:57% 0%}
    50%{background-position:44% 100%}
    100%{background-position:57% 0%}
}
@keyframes AnimationName {
    0%{background-position:57% 0%}
    50%{background-position:44% 100%}
    100%{background-position:57% 0%}
}

.editProfileJobs{
    width: 250px;
    padding: 10px;
    background-color: var(--white);
    border: solid 1px var(--warm-grey);
    border-radius: 10px;
    cursor: pointer;
    margin: 25px auto 0px;
}
.personalInfo  .profilePic img.levelIcon{
        position: absolute;
        bottom: -15px;
        right: -5px;
        width: 40px;
}
.profileInfo .profesionalInfo{
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.profileInfo .personalInfo .namePro{
   font-size: 16px;
   font-family: "SourceBold";
   margin-top: 10px;
   text-align: center;
}
.profileInfo .personalInfo .levelLabel{
    font-family: "SourceBold";

}
.profileInfo .profesionalInfo .item:nth-child(2){
   border-top: solid 1px var(--warm-grey);
   border-bottom: solid 1px var(--warm-grey);
   padding: 5px 10px;
   margin: 15px 0;
}
.profileInfo .profesionalInfo .item {
   width:  120px;
   padding: 0 10px;
   display: flex;
   justify-content: center;
   flex-direction: column;

}
.profileInfo .profesionalInfo .item .itemNumber{
    font-size: 21px;
   margin-bottom: -5px;
   display: flex;
   align-items: center;
}
.userDetails .profesionalInfo .item p{
    font-size: 13px;
    letter-spacing: 0.4px;
}
.itemNumber{
    font-size: 25px;
    font-weight: 700;
}
.itemNumber img{
    margin-left: 7px;
    width: 20px;
}
.accountState{
    display: flex;
    justify-content: start;
    padding: 15px;
    flex-direction: column;
    border: solid 1px var(--warm-grey);
    height: 32vh;
    width: 100%;
    margin: 15px 15px;
     border-radius: 25px;
}
.accountState h4 {
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 18px;
}

.accountState .accountItem{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-size: 15px;
}
.accountState .accountItem img{
    margin-right: 5px;
    width: 20px;
}
.accountState .accountItem img {
    width: 10px;
}
/*media queries*/
@media only screen and (max-width: 1400px) {
    /*tablet*/
   .detailsContainer.userDetails{
        padding: 10px;
        width: 35%;
    }
    .detailsContainer .profileInfo.card{
        margin: 10px 0 0;
        padding: 5px;
        justify-content: space-around;
        min-width: unset;
    }
    .profileInfo .personalInfo{
        width: auto;
    }
    .personalInfo .profilePic{
        width: 100px;
        height: 100px;
        margin-bottom: 10px;
        box-shadow: none;

    }
    .personalInfo  .profilePic img.levelIcon{
        bottom: -17px;
        right: -15px;
        width: 50px;
    
    }
    .profileInfo .profesionalInfo{
        justify-content: flex-end;
    }
    .accountState{
        margin: 20px 0 0;
        padding:  15px;
        min-width: unset;
        height: auto;
    }
    .accountState h3{
        font-size: 17px;
        margin-top: 5px;
    }
    .accountState .accountItem{
        margin-bottom: 0;
    }
    .itemNumber{
        font-size: 21px;
        font-weight: 700;
    }
    .detailsContainer p{
        letter-spacing: 0.3px;
        color:black !important;
        font-size: 13.5px;
    }
    .profileInfo .profesionalInfo .item:nth-child(2){
        border-top: solid 1px var(--warm-grey);
        border-bottom: solid 1px var(--warm-grey);
        padding: 5px 10px;
        margin: 10px 0;
     }
}
@media only screen and (max-width: 1170px) {
    /* tablet */
    .detailsContainer .profileInfo.card {
        
        min-height: 225px;
    }
    .accountState {
        padding: 10px;
        margin: 14px 0 0;
    }
    .accountState h4{
        font-size: 18px;
        margin: 0;
    }
   
}
@media only screen and (max-width: 576px) {
    /* phone */
    .detailsContainer.userDetails{
        width: 100%;
        height: 100%;
        padding: 0 15px 15px;
        position: relative;
    }
    .accountState, .editProfileJobs{
        display: none;
    }
    .detailsContainer .card.level{
        padding: 15px 20px 15px 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        height: 18vh;
        width: 90%;
        max-width: unset !important;
        border-radius: 25px;
        min-width: 300px;
        box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px 4px;
        min-height: unset;
        margin: 25px auto 0;
        border: transparent;
    
    }
    
    
    
    .detailsContainer .card.level.bronze{
        background-color: var(--black-wolly);
    }
    
    .detailsContainer .card.level.bronze .levelUpButton, .detailsContainer .card.level.plata .levelUpButton{
        border-radius: 10px;
        margin-top: 15px;
        padding: 8px 20px;
        font-size: 15px;
    }
    .detailsContainer .card.level.bronze .levelUpButton{
        border: solid var(--new-gold) 2px;
        color: var(--new-gold);
    }
    .detailsContainer .card.level.plata .levelUpButton{
        border: solid var(--black-wolly) 2px;
        color: var(--black-wolly);
    }
    .detailsContainer .card.level.platino{
        background: linear-gradient(31deg, #000000, #000, #ffffff, #000, #000);
    
        background-size: 100% 800%;
    
        -webkit-animation: AnimationName 4s ease infinite;
        -moz-animation: AnimationName 4s ease infinite;
        animation: AnimationName 4s ease infinite;
    }
    .detailsContainer .card.level.bronze p, .detailsContainer .card.level.platino p{
        color: var(--white) !important;
        text-align: center;
        font-size: 12px;
        margin-bottom: 0;
    }
    .detailsContainer .card.level .levelText{
        font-size: 30px !important;
        font-family: "SourceBold";
        line-height: 35px;
    }
    .detailsContainer .card.level.plata p, .detailsContainer .card.level.oro p{
        color: var(--black-wolly) !important;
        text-align: center;
    }
    .detailsContainer .card.level.plata{
        background-color: var(--black-wolly);
        background: linear-gradient(31deg, #7f7f7f, #f5f5f5, #949494, #ededee, #848484);
    
        background-size: 100% 800%;
    
        -webkit-animation: AnimationName 4s ease infinite;
        -moz-animation: AnimationName 4s ease infinite;
        animation: AnimationName 4s ease infinite;
    }
    .detailsContainer .card.level.oro{
        background-color: var(--black-wolly);
        background: linear-gradient(31deg, #bf953f, #fcf6ba, #b38728, #fbf5b7, #aa771c);
        background-size: 1000% 1000%;
    
        -webkit-animation: AnimationName 6s ease infinite;
        -moz-animation: AnimationName 6s ease infinite;
        animation: AnimationName 6s ease infinite;
    }
    .detailsContainer .card.level img{
        width: 80px;
        height: 80px;
    }
    
}

