

@font-face {
    font-family: SourceBold;
    src: url("../../assets/fonts/SourceSansProBold.ttf");
    font-weight: bold;
  }
@font-face {
    font-family: Source;
    src: url("../../assets/fonts/SourceSansProRegular.ttf");
  }
.ServiceDetailsModal {
    border-radius: 30px;
    min-height: calc(100vh - 12rem);
    width: 70%;
    max-width: 1400px;
    padding: 2.2rem;
}
.service-details-wrapper{
    font-family: Source;
    display: flex;
    flex-direction: row;
}

.service-details-data-wrapper{
    width: 65%;
    overflow-x: auto;
    padding-bottom: 20px;
}
.service-details-row-wrapper.title.Bronce , .service-details-row-wrapper.title.Bronce  .service-details-vertical{
    color:var(--bronze) !important;
    margin-left: 0;
}
.service-details-row-wrapper.title.Plata , .service-details-row-wrapper.title.Plata  .service-details-vertical{
    color:var(--warm-grey) !important;
    margin-left: 0;
}
.service-details-row-wrapper.title.Oro , .service-details-row-wrapper.title.Oro  .service-details-vertical{
    color:var(--new-gold) !important;
    margin-left: 0;
}

.service-details-row-wrapper, .service-details-row-wrapper-fixed{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}
.service-details-row-wrapper-fixed.profit{
    margin: 25px 0 25px 10px;
    align-items: baseline;
}
.service-details-row-wrapper-fixed  .profitData{
    font-size: 35px;
    font-family: 'SourceBold';
    letter-spacing: -1.4px;
    margin-left: 10px;
}
.service-details-row-wrapper-fixed.advice {
    
    margin-top: 20px;
    margin-bottom: 20px;
}
.service-details-row-wrapper-fixed.advice  .service-details-p-advice{
    font-size:12px ;
    width: 50%;
    margin-left: 10px;
    font-family:  Source;
}
.service-details-row-wrapper-fixed.advice img{
    height: 20px;
    width: 20px;
}
.service-details-row-wrapper img{
    height: 20px;
    width: 20px;
}
.service-details-row-wrapper .businessIcon{
    height: 30px;
    width: 30px;
    margin-left: 20px;
}
.service-details-row-wrapper .service-detail-level {
    font-family: SourceBold;
    border-radius: 15px;
    font-size: 24px;
    margin-left: 15px;
}
.service-details-row-wrapper .service-detail-vertical-img{
    width: 50px;
    height: 50px;
}

.service-details-data-wrapper .buyAndSlots{
    display: flex;
    justify-content: space-between;
    width: 60%;
    align-items: center;
    margin-top: 2vh;

}
.service-details-data-wrapper .uploadPhotos{
    display: flex;
    justify-content: space-between;
    width: 60%;
    flex-direction: column;

}

.service-details-vertical{
    font-size: 24px;
    font-family: SourceBold;
    margin-left: 20px;
    text-transform: uppercase;

}

.service-details-title{
    max-width: 80%;
    font-size: 26px;
    font-family: SourceBold;
    color: var(--black-wolly);

}
.service-details-profile-pic{
    width: 65px;
    height: 65px;
    margin-left: 10px;
    border-radius:50%;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: rgb(0 0 0 / 30%) -4px 6px 8px -3px;
    
}

.service-details-p{
    margin-left: 20px;
    margin-right: 20px;
    color: var(--black-wolly);
    font-size:16px;
    font-family: Source;
}

.service-details-description{
    font-size: 18px;
    font-family: SourceBold;
    margin-top: 20px;
    margin-bottom: 10px;
    color: var(--black-wolly);

}

.service-details-description-p{
    font-size: 16px;
    max-width: 80%;
    color: var(--black-wolly);
    font-size:16px;


}
.service-details-description {
    font-size: 16px;
    max-width: 80%;
    color: var(--black-wolly);
    font-size:16px;


}
.service-details-data-wrapper .service-details-wollyShop{
    width: 85%;
    display: flex;
    font-family: "Source";
    color: var(--black-wolly);
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    text-decoration: underline;
}
.service-details-data-wrapper .service-details-wollyShop img{
   margin-left: 10px;
}
.service-details-buy-button-wrapper{
 
    padding: 0 10px;
    width: 20%;
   
}
.service-details-buy-button{
    background: var(--black-wolly-darker);
    border: transparent;
    color: var(--white);
    border-radius: 15px;
    padding: 10px;
    width: 70%;
    display: flex;
    font-family: 'SourceBold';
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    box-shadow: rgb(0 0 0 / 30%) 0px 5px 5px 0px;
    height: 40px;
    margin-right: 15px;
    margin-top: 15px;
}
.service-details-buy-button-clear{
    background: var(--white);
    border: solid 2px var(--black-wolly);
    color: var(--black-wolly);
    border-radius: 15px;
    padding: 10px;
    width: 70%;
    display: flex;
    font-family: 'SourceBold';
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    box-shadow: rgb(0 0 0 / 30%) 0px 5px 5px 0px;
    height: 40px;
    margin-right: 15px;
}

.service-details-images-wrapper{
    width: 35%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 50px 15px 0;
}

.service-details-images-wrapper .service-details-images{
    width: 160px;
    height: 160px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 15px;
    background-position: center;
    margin: 0px auto 10px;

}
.service-details-images-wrapper .service-details-images-no-pics{
    width: 200px;
    height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 15px;
    background-position: center;
    margin: 0px auto;
    
}
.service-details-wrapper-mobile{
    display: none;
}

.service-details-banner{
    margin: 20px 0px;
}
@media only screen and (max-width: 1570px) {
    .dataContainer > .textContainer{
        max-width: 34vw;
    }
 }
@media only screen and (max-width: 1470px) {
    /* tablet */
    .ServiceDetailsModal {
        width: 94%;
    }
}
@media only screen and (max-width: 576px) {
    /* phone */
    .service-details-title-wrapper  {
        display: flex;
    }
    .ServiceDetailsModal .service-details-row-wrapper{
        display: flex;
        flex-direction: row;
    }
    .ServiceDetailsModal .service-details-row-wrapper img{
        width: 20px;
        height: 20px;
    }
    .ServiceDetailsModal .service-details-title-wrapper div {
        width: 75%;
        margin-bottom: 15px;
    }
    .service-details-p {
        margin-left: 10px;
        margin-right: 0;
        color: var(--black-wolly);
        font-size: 12px;
    }
   .service-details-info-wrapper{
    display: flex;
    justify-content: space-between;
   }
   .service-details-profile-pic{
        width: 50px;
        height: 50px;
        margin-right: 10px
        
   }
   .service-details-images-wrapper {
        width: 100%;
        padding: 0;
        margin: 20px 0;
    }
    .service-details-container-wrapper{
        display: flex;
        flex-direction: column;
    }
    .service-details-title-wrapper .service-detail-vertical-img {
        width: 30px;
        height: 30px;
        margin-right: 5px;
    }
    .service-details-title-wrapper img {
        width: 10vw;
        height: 10vw;
    }
    .service-details-title-wrapper img:last-child {
        width: 10vw;
        height: 10vw;
        margin-left: auto;
    }
    .service-details-title {
        font-size: 15px;
        font-family: SourceBold;
        color: var(--black-wolly);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .service-details-description {
        font-size: 14px;
    }
    .service-details-data-wrapper .service-details-wollyShop {
    display: flex;
    color: var(--black-wolly);
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 15px;
    margin-top: 20px;
    width: 100%;
    margin: 20px auto 0;
    }
    .service-details-buy-button-wrapper {
        padding: 0;
        margin: 30px 0 0;
        width: 100%;
    }
    .service-details-buy-button-wrapper .service-details-buy-button {
        width: 77%;
        padding: 15px 20px;
        margin: 20px auto 0;
        position: fixed;
        bottom: 12%;
        left: 50%;
        transform: translate(-50%);
    
        
    }
    .service-details-share-button{
        width: 77%;
        padding: 15px 20px;
        margin: 20px auto 0;
        position: fixed;
        bottom: 7.5%;
        left: 50%;
        transform: translate(-50%);
        border: none;
        background-color: transparent;
        text-decoration: underline;
        font-family:  Source;
    }
    .service-details-description-p {
        font-size: 12px;
        max-width: 100%;
        color: var(--black-wolly);
        font-family:  Source;
    }
    .service-details-wrapper-mobile{
        display: flex;
    }
    .service-details-wrapper {
        display: none;
    }
    .service-details-data-wrapper {
        width: 100%;
        padding-bottom: 0;
        height: 70vh;
        overflow-y: auto;
    
    }
    .ServiceDetailsModal {
        border-radius: 30px;
        height: 84vh ;
        width: 100%;
        max-width: 1400px;
        padding: 2rem 1.5rem;
        overflow-y: auto;
    }

    .service-details-title-wrapper div{
        width: 50%;
    }

    .Oro{
        font-family: SourceBold;
        text-transform: uppercase;
        color:var(--new-gold) !important;
    }
    
    .Platino{
        font-family: SourceBold;
        text-transform: uppercase;
    }
    
    .Plata{
        font-family: SourceBold;
        text-transform: uppercase;
        color:var(--warm-grey) !important;
    }
    
    .Bronce{
        font-family: SourceBold;
        text-transform: uppercase;
        color:var(--bronze) !important;
    }

    .service-details-buy-button-clear{
        margin-top: 10px;
    }

    .mt-10{
        margin-top: 10px;
    }

    .service-details-fixed-price{
        font-family: SourceBold;
        text-transform: uppercase;
        margin-left: 7px;
        font-size: 24px;
    }

    .service-details-p-advice{
        padding: 10px;
        font-size: 10px;
        font-family:  Source;
    }

    .service-details-p-complete{
        font-family: SourceBold;
        text-transform: uppercase;
        font-size: 18px;
    }

    .service-details-fixed-ganancy{
        font-family:  Source;
    }

    .service-details-banner{
        width: 100%;
    }
}