.paginator.container {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 64;
    text-align: center;
    border-top: 1px solid var(--soft-grey);
    padding: 8;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--white);
    width: 100%;
}

@media only screen and (max-width: 768px) {
    
    .paginator.container {
        padding: 8px 0;
        left: 0;
        background-color: var(--black-wolly);
        color: var(--coral);
    }

}