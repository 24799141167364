html,
body {
    height: 100%;
}


/*kpis butons*/
.filter.iskpis > button {
    cursor: pointer;
    color: #fff;
    padding: 16px;
    border: 1px solid var(--kpis_filter);
    background-color: var(--kpis_filter);
    border-radius: 4px;
}
.filter.iskpis > button:hover {
    background: var(--kpis_filter);
    border-color: var(--kpis_filter);
}
.filter.iskpis > button:focus,
.filter.iskpis > button.active {
    outline: none;
    color: #fff;
    background: black;
    border-color: var(--kpis_yelow);
}
.filter.iskpis > button.active:hover {
    border-color: var(--kpis_yelow);

}
.filter.iskpis .menu.iskpis{
    display:flex;
    transition: all 0.3s;
}
.filter.iskpis .menu.iskpis.active{
    display:flex;
    width: 400px;
    height: 460px;
    transition: all 0.3s;

}
div.filter.custom.date-no-hide.iskpis div.DateInput.DateInput_1 {
    width: 150px !important;
    margin-right: 5px;
}
 
/*kpis butons*/


.filter > button {
    cursor: pointer;
    padding: 8px 12px;
    border: transparent;
    background-color: var(--soft-grey);;
    border-radius: 4px;
    font-weight: 600;
    color:  var(--greyish-brown);
    margin-right: 6px;
}
.filter > button:hover {
    background: var(--soft-grey);
    border-color: var(--soft-grey);
}
.filter > button:focus,
.filter > button.active {
    outline: none;
    color: var(--coral);
    background: black;
    border-color: var(--greyish-brown);
}
.filter > button.active:hover {
    background: #000000;
}
.filter {
    position: relative;
    display: inline-block;
    margin: 5px 0;
}
.iskpis.filter .menu {
    position: absolute;
    top: 58px;
    left: 0px;
    z-index: 10;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 14px 36px 2px;
    max-height: calc(100vh - 152px);
    overflow-y: auto;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 24px;
    /* max-width: calc(100vw / 3.5); */
}
.filter .menu, .filter.date-no-hidesearch .menu {
    position: absolute;
    top: 40px;
    left: 0px;
    z-index: 10;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 14px 36px 2px;
    max-height: calc(100vh - 152px);
    overflow-y: auto;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 24px;
    height: 475px;
}
.filter.date-no-hidesearch .menu{
    width: 700px;

}
.filter .menu.right {
    left: unset;
    right: 0;
}
.filter .menu .items-filter ul {
    list-style: none;
    padding-left: 0;
}
.filter .menu .items-filter svg {
    width: 20px;
}
.filter .menu .items-filter li {
    display: block;
}
.filter .menu .items-filter li + li {
    margin-top: 8px;
}
.filter .menu .items-filter label {
    cursor: pointer;
    display: block;
}
.filter .menu .items-filter label > * {
    vertical-align: middle;
}
.filter .menu .items-filter label[for="all"],
.filter .menu .items-filter label[for="none"] {
    padding-bottom: 8px;
    border-bottom: solid 1px var(--soft-grey);
}
.filter .menu .items-filter label span {
    margin-left: 8px;
}

.filter.custom h3 {
    margin-top: 0;
    font-size: 18px;
    border-bottom: solid 1px var(--soft-grey);
}
