#service-type .search-service {
    position: relative;
    margin-bottom: 40px;
}
#service-type .jobin-input-search.showing-results {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 4px 8px 0 rgba(0, 0, 0, 0.05);
}
#service-type .search-service .query-results {
    margin-top: 0;
    padding-left: 0;
    padding-bottom: 12px;
    list-style: none;
    position: absolute;
    z-index: 2;
    top: 49px;
    width: 100%;
    background-color: #fff;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 4px 8px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px var(--white-two);
    border-top: none;
}
#service-type .jobin-app-navbar .search-service .query-results {
    top: 41px;
}
#service-type .search-service .query-results a {
    display: block;
    padding: 8px 16px 8px 48px;
    font-size: 14px;
    color: var(--greyish-brown);
    text-decoration: none;
}
#service-type .search-service .query-results a:hover {
    cursor: pointer;
    background-color: var(--soft-grey);
}
