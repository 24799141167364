.new-service-modal .list-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px;
}
.new-service-modal .item-list{
    color: black;
    font-size: 17px;
}

.new-service-modal .button-go{
    height: 37px;
    padding: 0;
    width: 110px;
    background-color: black;
    border: 2px solid black;
    color: var(--coral);
    margin-top: 50px;
    margin-right: auto;
    border-radius: 3px;
}
.new-service-modal .form-container,.new-service-modal .form-container-active {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1px solid grey;
    border-bottom: solid 1px grey;
    height: 0px;
    overflow: hidden;
    padding: 0px 25px;
    transition: all 0.3s;
    width: 100%;
}
.new-service-modal .form-container-active {
    height: 58vh;
    padding: 0px 25px 25px;
    transition: all 0.3s;

}
.new-service-modal .container-input {
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-top: 12px;
    justify-content: space-between;
}
.new-service-modal .input-slot {
    width: 49%;
    display: flex;
    flex-direction: column;
}
.new-service-modal .input-slot.unique {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.new-service-modal .input-slot.triple {
    width: 49%;
    display: flex;
    flex-direction: column;
    transition: all 0.3s;
}
.new-service-modal .input-slot.triple.hidden {
    width: 0%;
    opacity: 0;
    display: flex;
    flex-direction: column;
    transition: all 0.3s;
}
.new-service-modal .input-slot.triple.active {
    width: 31%;
    display: flex;
    flex-direction: column;
    opacity: 1;
    transition: all 0.3s;
}


.new-service-modal .input-slot label {
    width: 90%;
}

.new-service-modal .input-slot.right label{
    margin-right: auto;
}
.new-service-modal .input-slot span.error{
    opacity: 1;
    transition: all 0.3s;
}
.new-service-modal .input-slot span.error.hidden{
    opacity: 0;
    transition: all 0.3s;
    display: block !important;
}
.new-service-modal select,.new-service-modal input, .new-service-modal textarea{
    width: 100%;
    max-width: 100%;
    height: 32px;
    border-radius: 4px;
    font-size: 16px;
    color: var(--black);
    padding: 4px 16px;
    background: var(--white);
    border: 1px solid var(--greyish);
    outline: none;
    display: flex;
}

.new-service-modal .list-container select{
    width: 100%;
    max-width: 100%;
}
.new-service-modal textarea{
    height: 65px;
    width: 100%;
    max-width: 100%;
}
.new-service-modal .add-photos {
    background:  var(--white-two);
    border: solid 1px;
    color: var(--black);
    border-radius: 5px;
    padding: 7px;
    width: 100% !important;
    display: flex;
    font-family: 'HurmeBold';
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    height: 35px;
}
.new-service-modal .input-slot .photo-container{
   width: 100%;
   height: 60px;
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   padding: 5px;
}
.new-service-modal .photo {
    width: 60px;
    height: 60px;
    border-radius: 5px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin:  5px ;
    position: relative;
}
.new-service-modal .photo:hover::before {
    content: "❌";
    text-align: center;
    background: var(--background-shadow);
    color: var(--white);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.new-service-modal .add-photos .error{
    color: var(--kpis_red);
}