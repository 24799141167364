.step-tip {
    margin-top: 48px;
    padding-left: 24px;
    border-left: solid 1px var(--light-grey);
}
.step-tip .title {
    margin-top: 16px !important;
    font-weight: 600 !important;
    font-size: 16px;
}
.step-tip .excerpt {
    font-size: 14px;
    margin-bottom: 0;
}
.step-tip img.right {
    display: block;
    margin-left: auto;
}