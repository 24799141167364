.new-incidence-title{
    margin-bottom: 15px;
    margin-top: 40px;
    font-weight: bold;
}

.imageView{
    position: "relative";
    height: 56px;
    width: 56px;
    margin-right: 12px;
}

.image{
    height: 56px;
    width: 56px;
    border-radius: 28px;
    overflow: "hidden";
    object-fit: "cover";
}

.medalImage{
    position: "absolute";
    height: 20px;
    width: 20px;
    bottom: -3px;
    right: -3px;
}

.woller-wrapper{
    display: flex;
    flex-direction: row;
    padding: 20px;
    border: none;
    outline: none;
    border-radius: 10px;
    margin-top: 12px;
}

.dataView{
    display: "flex";
    flex: 1;
    flex-direction: "column";
    width: 200px;
}

.nameLabel{
    font-weight: bold;
    line-height: 1.8em;
    max-height: 1.8em;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    margin-left: 15px;
}

.pointsLabel{
    color: var(--coral);
    margin-left: 8;
    margin-right: 3;
    font-weight: "bold";
}