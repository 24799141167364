.purchased-jobs-wrapper{
    padding: 5vh 4%;
    font-family: Source;
}
.purchased-jobs-wrapper .job-type-selector-wrapper{
    display: block;
    width: 24%;
}
.purchased-jobs-wrapper .job-type-selector-wrapper .multiSelectContainer{
    width: 100%;
    margin: 10px 0 0;
}
.purchased-jobs-wrapper .job-type-selector-wrapper .multiSelectContainer .optionList{
   top:60px;
}
.purchased-jobs-wrapper .job-type-selector-wrapper-mobile{
    display: none;
}

.purchased-jobs-type-button{
    width: 34%;
    padding: 10px 15px;
    background-color: var(--bone-light);
    border-bottom: 2px solid var(--white-two);
    font-family: SourceBold;

}

.loading-complete{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.selected{
    background-color: #dddcdc;
}

.purchased-jobs-title-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 4vh;
    margin-bottom: 1vh;
}

.purchased-jobs-title{
    font-size: 26px;
    font-family: SourceBold;
}

.purchased-jobs-nJobs-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: SourceBold;
    font-size: 18px;
}

.purchased-jobs-main-wrapper{
    width: 100%;
    height: 85vh;
}

.purchased-jobs-stats-wrapper{
    display: flex;
    justify-content: center;
    height: 85vh;
    position: fixed;
    right: 4%;
    width: 20%;
}

.purchased-jobs-data{
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow-y: auto;
    margin-top: 10px;
}

.purchased-jobs-main-cell{
    display: flex;
    flex-direction: row;
    width: 98%;
    border: 1px solid grey;
    padding: 25px;
    border-radius: 20px;
    margin-top: 20px;
    position: relative;
}

.purchased-jobs-main-cell-closed{
    background: var(--soft-grey);
}

.purchased-jobs-main-cell .jobType {
    border: solid 1.5px var(--black-wolly);
    font-size: 13px;
    top: 5px;
    left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 15%;
    background: var(--white);
}

.purchased-jobs-main-details-title-wrapper{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 20px;
}

.purchased-jobs-main-details-title{
    font-family: SourceBold;
    font-size: 20px;
    margin-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 44vw;
}

.purchased-jobs-main-details-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
    width: 90%;
}

.purchased-jobs-main-footer-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    max-width: 790px;
    width: 90%;
}


.purchased-jobs-main-details-wrapper img{
    width: 15px;
    height: 15px;
}

.purchased-jobs-main-details-p{
    margin-left: 10px;
}

.purchased-jobs-main-details{
    width: 70%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.purchased-jobs-main-details .bussinessImg{
    width: 60px;
}
.purchased-jobs-main-details .balanceButton{
    width: 200px;
    margin-top: 20px;
}

@media (min-width: 580px) {
    .purchased-jobs-main-details .balanceButton {
        position: absolute;
        right: 2em;
    }
    .purchased-jobs-main-details .bussinessImg{
        position: absolute;
        right: 2em;
    }
}

.purchased-jobs-flex-jobs{
    display: flex;
    flex-direction: row;
}

.purchased-jobs-stats{
    margin-top: 20px;
    border: 1px solid grey;
    padding: 22px;
    border-radius: 20px;
    width: 95%;
    height: 250px;  
    box-shadow: 0px 11px 20px 1px rgba(130,130,130,0.67);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.purchased-jobs-stats-only-jobs{
    height: 150px;
}

.w-full{
    width: 100%;
}

.purchased-jobs-stats-number{
    font-family: SourceBold;
    font-size: 24px;
}

.purchased-jobs-stats-p{
    font-size: 14px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid grey;
    width: 100%;
}

.purchased-jobs-stats-responsive-wrapper{
    display: none;
}

.purchased-jobs-main-details-footer-p{
    font-family: SourceBold;
    font-size: 20px;
}

.job-type-wrapper{
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media only screen and (max-width: 576px) {
    .purchased-jobs-wrapper .job-type-selector-wrapper{
        display: none;
    }
    .purchased-jobs-wrapper .job-type-selector-wrapper-mobile{
        display: block;
    }

    .purchased-jobs-data{
        width: 100%;
    }

    .purchased-jobs-main-details-p {
        font-size: 14px;
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .purchased-jobs-title-wrapper{
        display: none;
    }

    .purchased-jobs-stats-responsive-wrapper{
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        width: 100%;
        height: 18vh;
        padding-top: 2vh;
        padding-bottom: 2vh;
        box-shadow: -2px 10px 23px -6px rgba(0,0,0,0.75);
    }

    .purchased-jobs-stats-wrapper{
        display: none;
    }

    .purchased-jobs-wrapper{
        padding: 0vh 0%;
    }
    .purchased-jobs-main-details, .purchased-jobs-main-footer-wrapper {
        width: 100%;
    }
    .purchased-jobs-main-footer-wrapper {
        flex-direction: column;
    }
    .purchased-jobs-main-details-wrapper {
        width: 100%;
    }
    
    
    .purchased-jobs-main-details .balanceButton {
        margin: 20px auto 0;
    }
    

    .purchased-jobs-stats-p{
        max-width: 150px;
        padding-bottom: 0px;
        border-bottom: none;
    }

    .purchased-jobs-main-wrapper{
        height: 68vh;
        width: 100%;
    }

    .purchased-jobs-main-cell{
        display: flex;
        flex-direction: row;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        border: 1px solid grey;
        padding:25px 15px;
        border-radius: 25px;
        margin-top: 30px;
    
        box-shadow: rgb(0 0 0 / 30%) 0px 5px 5px 0px;
    }
    .purchased-jobs-main-cell .jobType{
        font-size: 14px;
        top: -5%;
        left: 50%;
        transform: translate(-50%);
        width: 40%;
        background: var(--white);
    
    }

    .purchased-jobs-main-cell .jobType .yellow{
        border: 1px solid var(--coral);
    }

    .purchased-jobs-type-button{
        width: 50%;
        padding: 10px 15px;
        background-color: var(--bone-light);
        border-bottom: 2px solid var(--white-two);
        font-family: "Source";
    }
    
    .selected{
        font-family: "SourceBold";
        background-color: #dddcdc;
    }

    .job-type-wrapper{
        display: none;
    }
}