
@font-face {
    font-family: SourceBold;
    src: url("../../assets/fonts/SourceSansProBold.ttf");
    font-weight: bold;
  }
@font-face {
    font-family: Source;
    src: url("../../assets/fonts/SourceSansProRegular.ttf");
  }

.searchNavContainer{
    display: flex;
    width: 100%;
    justify-content:start;
    align-items: center;
}
.searchNavContainer .title{
    margin: 0 auto;
}
.searchNavContainer .buttonFilter{
    margin: 0;
    padding: 10px 30px;
    width: 18%;
    border: none;
    border-bottom: 2px solid var(--greyish-brown);
    border-radius: 4px;
    color: var(--black-wolly);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--bone-light);
    cursor: pointer;
}
.searchNavContainer .buttonFilter:hover,.searchNavContainer .buttonFilter.active{
    background-color: var(--black-wolly);
    font-family: "SourceBold";
    color: var(--white);
    border-bottom: 2px solid var(--white);

}
.searchNavContainer .buttonFilter.center{
    margin-left: 1px;
    margin-right: 1px;
}
@media only screen and (max-width: 576px) {
    /* phone */
    .searchNavContainer{
        display: none;
    }
}
