
@font-face {
    font-family: Source;
    src: url("../assets/fonts/SourceSansProBold.ttf");
    font-weight: bold;
  }
@font-face {
    font-family: Source;
    src: url("../assets/fonts/SourceSansProRegular.ttf");
  }
:root {
    --bronze:#cd7f32 ;
    --new-gold:#ffc41e;
    --greyish: #b0b0b0;
    --coral: #FFC41E;
    --green: #15f700;
    --warm-grey: #848484;
    --white:#F9F9F9;
    --white-two: #e2e2e2;
    --white-four: #f0eee9;
    --light-grey: #e7e3db;
    --black-wolly: #262626;
    --black-wolly-darker: #344DFF;
    --bone-light: #f8f7f6;
    --greyish-brown: #444444;
    --soft-grey: #f2f2f2;
    --kpis_background: #06141d;
    --kpis_filter: #1A3B42;
    --kpis_red: #FF3569;
    --kpis_green: #35FFC8;
    --kpis_yelow: #FAE35B;
    --offset: 187;
    --duration: 1.4s;
    --golden-shadow: 0px 1px 15px 2px var(--coral);
    --background-shadow :#000000b8;
    --shadow: rgb(0 0 0 / 5%) 0px 4px 8px 0px
}

/* INDEX
# semantic elements
# utility classes
# specific classes
# helper DOM element in conjunction with images and text
# grid modifitacions
# inputs
# input search
# buttons
# lists with radio buttons
# alerts 
# warnings
# navbar
# navbar toggle
# footer
# section
# responsive
*/


/*
semantic elements
*/
html{
    max-width: 100vw;
    max-height: 100vh;
    width: 100vw;
    height: 100vh;
    overflow:hidden;
}
body {
    font-family: "Source", sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: var(--greyish-brown);
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow:auto;
}


::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: var(--white);
}

::-webkit-scrollbar
{
	width: 12px;
	height: 12px;
	background-color: var(--white);
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #626262;
}

#root{
    height: 100vh;

}
#root > div{
    height: 100vh;

}


p {
    font-size: 14px;
    color: var(--greyish-brown);
}
p b {
    font-weight: 600;
}
p + p {
    margin-top: 0;
}

h1 {
    font-size: 40px;
    font-weight: 600;
    color: var(--greyish-brown);
    line-height: 1.17;
}
h2 {
    font-size: 32px;
    font-weight: 600;
    color: var(--greyish-brown);
}
h3 {
    font-size: 28px;
    font-weight: normal;
    color: var(--greyish-brown);
}
h3 b {
    font-weight: 600;
}
h4 {
    font-size: 24px;
    font-weight: 600;
    color: var(--greyish-brown);
}
h5 {
    font-size: 18px;
    font-weight: 400;
}

hr {
    height: 1px;
    border: none;
    margin-top: 32px;
    margin-bottom: 32px;
    box-shadow: inset 0 -1px 0 0 var(--white-two);
}

p {
    margin: 0;
}
textarea {
    resize: none;
  }


/*utility classes*/
    .w-50{
        width: 50%;
    }
    .position-relative {
        position: relative;
    }

    .no-scroll {
        overflow: hidden;
    }

    .hidden {
        display: none !important;
    }
    .v-hidden{
        visibility:hidden  !important;
    }
    .visible {
        display: initial !important;
    }
    .native-hidden {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        position: absolute;
        left: -999px;
    }

    .noselect {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .noscroll {
        position: relative;
        height: 100%;
        overflow: hidden;
    }

    button.nostyle {
        background-color: transparent;
        border: none;
        padding: 0;
    }

    .img-container {
        line-height: 0;
    }
    .img-responsive {
        max-width: 100%;
        margin: auto;
    }

    .no-padding-left-right {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .no-margin-top-bottom {
        margin-top: 0;
        margin-bottom: 0;
    }
    .margin-top-0 {
        margin-top: 0;
    }
    .margin-top-32px {
        margin-top: 32px;
    }
    .margin-top-48px {
        margin-top: 48px;
    }
    .margin-top-64px {
        margin-top: 64px;
    }
    .margin-top-72px {
        margin-top: 72px;
    }
    .margin-bottom-16px {
        margin-bottom: 16px;
    }

    .padding-top-0 {
        padding-top: 0;
    }
    .padding-bottom-0 {
        padding-bottom: 0 !important;
    }
    .padding-top-bottom-128px {
        padding-top: 128px !important;
        padding-bottom: 128px !important;
    }

    .text-center {
        text-align: center;
    }
    .text-right {
        text-align: right;
    }

    .line-height-14 {
        line-height: 1.4;
    }
    .line-height-154 {
        line-height: 1.54;
    }
    .line-height-22px {
        line-height: 22px;
    }
    .line-height-24px {
        line-height: 24px;
    }
    .line-height-26px {
        line-height: 26px;
    }
    .line-height-28px {
        line-height: 28px;
    }
    .display-flex{
        display: flex;
    }

    .flex-column{flex-direction: column !important;}
    .flex-row{flex-direction: row !important;}
    .flex-align-between{align-items: space-between;}
    .flex-align-center{align-items: center;}
    .flex-align-end{align-items: flex-end;}
    .flex-justify-center{justify-content: center;}
    .flex-justify-around{justify-content: space-around;}
    .flex-justify-between{justify-content: space-between;}
    .flex-justify-evenly{justify-content: space-evenly;}
    .flex-wrap-wrap{flex-wrap: wrap;}
    .mx-auto{margin-left: auto !important ;margin-right:auto !important ;}
    .ml-auto{margin-left: auto !important }
    .mr-auto{margin-right:auto !important ;}

    .activateButton,
    .activatedButton{
        height: 35px ;
        width: 180px ;
        margin:0 7.5px;
        padding:  0px;
        border-radius: 8px;
        transition: all 0.5s;
        cursor:pointer;
    }
    .activateButton{
        background-color: var(--black-wolly) ;
        border: 2px solid var(--black-wolly)  ;
        color:var(--coral) ;   
    }
    .activateButton:disabled {
        background-color: rgb(55, 55, 55) ;
        border: 2px transparent   ;
        color:var(--coral) ;   
        cursor:unset;
    }
    .activatedButton{
        border:  transparent;
        background-color: #1536ff;
        color: white;
        box-shadow:  rgb(0 0 0 / 30%) 0px 0px 10px 3px;
        
    }
    .buttonImageContainer{
        display: flex;
        flex-direction: column;
        border: 0;
        padding: 12;
        color: var(--greyish-brown);
        align-items: center;
        justify-content: center;
        outline: 0;
        font-size: 12;
        width: 80;
        background-color: #00000000;
    }
    .buttonImageContainer:hover[class="buttonImageContainer"]{
        background-color: var(--soft-grey);
        border-radius: 4;
    }
    .basicModal{
        width: 65% !important;
        max-width: unset !important;
        border-radius: 25px !important;
        color: var(--black-wolly) !important;
    }
    .basicModalSmall{
        width: 35% !important;
        max-width: unset !important;
        border-radius: 25px !important;
        color: var(--black-wolly) !important;
    }
/*utility classes*/
/*specific classes*/
    .result-cards-container{
        overflow-x: auto;
        padding: 16px;
        padding-bottom: 32px;
        position: relative;
        z-index: 0;
        display: flex;
        flex-direction: column;
        height:80%;
    }
    .card{
        display: flex;
        flex-direction: column;
        border: 2px solid var(--soft-grey);
        padding: 16px 16px 20px;
        margin: 15px 15px;
        border-radius: 4px;
        background-color: #fff;
        max-width: 300px;
        min-width: 300px;
        min-height: 200px;
        border-radius: 4px;
        box-shadow: rgb(0 0 0 / 30%) 0px 5px 5px 0px;
        justify-content: space-around;
    }
    .sectionTitle{
        font-size: 22px;
        width: 100%;
        text-align: center;
        margin: 10px 0;
    }
    
    
/*specific classes*/
/*import excel services*/
.ImportServicesExcel .table-responsive{
    max-height: 300px;
    overflow: auto;
    margin-bottom: 10px;
}
/*import excel services*/

/*hold standBy button*/
    .buttonHold{
    
        display: inline-block; 

    }
    .buttonNoHold{
        background: unset;
        color: var(--black-wolly);
        display: inline-block;
    }
    .buttonNoHold:hover{
        background-color: var(--soft-grey);
        border-radius: 4;
        display: inline-block;
    }
/*hold standBy button*/

/*crmManager*/

    .goldRow{
        background-color: #ffd70038;
    }
    .b2bRow{
        background-color: #1536ff38;
    }
/*crmManager*/
/*Match*/

.serchNavTypeJobsContainer{
    background-color: var(--soft-grey);
    border-radius: 4px;
    margin-top: 8px;
}
.serchNavTypeJobsSelect{
    background-color: transparent;
    position: absolute;
    height: 48px;
    outline: 0;
    border: none;
    z-index: 1;
    left: 8px;
}
.serchNavTypeJobsInputContainer{
    position: relative;
    margin-left: 146px;
}
.serchNavJobsInput{
    width: 100%;
    height: 48px;
    border: none;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    font-size: 16px;
    line-height: 1.5;
    padding: 12px 12px 12px 48px;
    background-color: var(--soft-grey);
    -webkit-appearance: none;
    border: 1px transparent;
    color: var(--greyish-brown);
}
.serchNavJobsInput:focus-visible {
    outline: transparent;
    background-color: var(--white);
}
.serchNavJobsInput:focus {
    outline: none;
    background-color: var(--white);
    box-shadow: rgb(0 0 0 / 5%) 0px 4px 8px 0px;
    border-color: var(--light-grey);
}
.serchNavJobsIconSearch{
    padding: 0;
    background-color: transparent;
    border: none;
    position: absolute;
    top: 50%;
    left: 16px;
    -webkit-transform: translate(0,-50%);
    -ms-transform: translate(0,-50%);
    transform: translate(0,-50%);
}
/*searchJobs*/
/*Match*/
.loading-container.match{
    height: 90vh;
    margin-top: 25px;
}
.table-wrapper-match{
    padding: 8px 14px 0;
    height: 90%;
    overflow-y: auto;
}
.tableSearch-match{
    width: 100%;
}

.tableSearch-match thead tr th{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: var(--white);
}
.tableSearch-match tbody tr:hover{
    background-color: var(--soft-grey);
    cursor: pointer;
}
.tableSearch-match td {
    text-align: center;
}
.tableSearch-match .longText{
    width: 13%;
}
.tableSearch-match .mediumText{
    width: 6%;
}
.tableSearch-match .incidenceRow{
    display: flex;
    flex-wrap: wrap;
    max-width: 50%;
    justify-content: center;
    align-items: center;
    margin: 15px auto 0;
}
.search-nav.Matchs{
    margin-bottom: 25px;
}

/*Match*/
    /*mediator*/
    .mediatorSearchContainer{
        margin-bottom: 17px;
    }
    .mediatorSearch{
        width: 26%;
        height: 48px;
        border-radius: 4px 0 0 4px;
        -webkit-border-radius: 4px 0 0 4px;
        font-size: 16px;
        line-height: 1.5;
        padding: 12px;
        background-color: var(--soft-grey);
        -webkit-appearance: none;
        border: transparent;
        border-right: 1px solid var(--greyish);
    }
    .mediatorSearch:focus-visible{
        outline:unset
    }
    .mediatorSearchButton{
        width: 7%;
        height: 48px;
        border-radius: 0px 4px 4px 0 ;
        font-size: 16px;
        line-height: 1.5;
        padding: 12px;
        background-color: var(--soft-grey);
        -webkit-appearance: none;
        border: #0000;
        cursor: pointer;
    }
    .mediatorSearchButton:active{
        background-color: var(--greyish);
    }
/*crmManager*/

/*mediator*/

/*custom Scrollbar*/
    #messageView::-webkit-scrollbar {
        width: 6px;
    }
    
    /* Track */
    #messageView::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
        -webkit-border-radius: 10px;
        border-radius: 10px;
    }
    
    /* Handle */
    #messageView::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: var(--kpis_yelow); 
        border: solid 0.1px var(--black-wolly);
    }
    #messageView::-webkit-scrollbar-thumb:window-inactive {
        background: var(--kpis_yelow); 
    }
/*custom Scrollbar*/



.editable-info-cell{
    display: flex;
    flex-direction: column; 
    margin-top: 8px; 
    padding-top: 8px; 
    padding-bottom: 8px; 
    border-bottom: 1px solid var(--soft-grey); 
    width: 47%; 
}
.editable-info-cell.left-cell{
    margin-right: 3%;
}
.editable-info-cell.right-cell{
    margin-left: 3%;
}

.desestimated{
    background-color:#FF3569;
    font-weight:Bold;
    color:white;
    height: 36px !important;
    padding: 0 !important;
    border: 2px solid #FF3569;
}
.estimated{
    background-color: #35FFC8 !important;
    font-weight: Bold;
    color:white !important;
      height: 36px !important;
    padding: 0 !important;
    border: 2px solid #35FFC8 !important;
 }

 .desestimated:hover{
    background-color:#FF3569 !important;
    font-weight:Bold;
    color:white;
    height: 36px !important;
    padding: 0 !important;
    border: 2px solid #FF3569 !important;
}
.estimated:hover{
    background-color: #35FFC8 !important;
    font-weight: Bold;
    color:white;
    height: 36px !important;
    padding: 0 !important;
    border: 2px solid #35FFC8 !important;
 }
 .stateButton{
    margin-top:5px;
    height: 36px;
    padding: 0px;
    width: 110px;
    background-color: var(--black-wolly);
    border: 2px solid var(--black-wolly) ;
    color:var(--coral);
 }
 .stateButtonOnly{
    margin-top:5px !important;
    height: 36px !important;
    padding: 0px !important;
    width: 110px !important;
    background-color: var(--black-wolly) !important;
    border: 2px solid var(--black-wolly)  !important;
    color:var(--coral) !important;
 }
 .stateButton:hover, .reviewed:hover{
    background-color: var(--black-wolly);
    color:white;  
 } 
 .review{
    background-color: #1D35F5 !important;
    border-color: #1D35F5 !important;
    color: #fff !important;
    height: 36px !important;
    padding: 0 !important;
 }
 .reviewed{
    margin-top:5px !important;
    height: 36px !important;
    padding: 0px !important;
    width: 110px !important;
    background-color: var(--coral) !important;
    border: 2px solid var(--black-wolly) !important;
    color:var(--black-wolly);
 }
 .reviewed:hover{
    
    color:var(--black-wolly);
 }
 .pending{
    height: 36px !important;
    padding: 0 !important;
    background-color: #B1B8D6!important;
    border-color: #B1B8D6!important;
    color: #FFF!important;
 }

/*kpis classes*/
    .Kneutral::before{
        content:"\25C4 ";
        color: var(--coral);
    }
    .Kpositive::before{
        content:"\25B2 ";
        color: var(--kpis_green);
    }
    .Knegative::before{
        content:"\25BC ";
        color: var(--kpis_red);
    }
    .Kneutral,
    .Kpositive,
    .Knegative
    {
        margin-top: 10px;
    }
    .Kneutral{
        color: var(--coral);
        font-size:11px
    }
    .Kpositive{
        color: var(--kpis_green);
        font-size:11px
    }
    .Knegative{
        color: var(--kpis_red);
        font-size:11px
    }
    .singleData{
        font-size: 55px;
        margin:0;
        line-height:0.8;
        color: var(--coral);
    }
    .duplexData{
        font-size: 30px;
        margin:0;
        line-height:0.8;
        color: var(--coral);
    }
    .singleContextTitles,.contextTitles,.globalContext{
        margin-bottom:30px;
        color:var(--white);
        font-weight: 700;
        cursor: default;
        font-size: 18px;    
        text-align: center;
    }
    .contextTitles{
    
        font-size: 13px;    
    }
    .duplexBox .singleContextTitles{
        margin:0;   
        text-align: center;
    }
    .contextTitles{
        margin:5px 0px;
    }
    .AccordeonKpis{
        position: relative;
    }
    .accordeonTrigger{
        transform: rotate(270deg);
        color: var(--coral);
        font-weight: 400;
        width: 324px;
        text-align: center;
        z-index: 33;
        background-color: var(--kpis_filter);
        transition: none;
        height: 25px;
        display: block;
        position: absolute;
        top: 204px;
        left: -165px;
    }
    .accordeonContainer{
        display: flex;
        flex-flow: row;
        transition: all 0.3s;
        justify-content: space-around;
        position: relative;
        height: 355px;
    }
    .AccordeonKpis.opened .accordeonContainer{
        width: 63vw;
    }
    .AccordeonKpis.closed .accordeonContainer{
        width: 0vw;
        overflow: hidden; 
    }


    .globalContext{
        margin:12px 0px;
    }
    .singleBox,.duplexBox{
        background-color:var(--kpis_background);
        padding: 10px 0;
        border-radius: 14px;
    }
    .singleBox{
        width: 16vw;
    }
    .duplexBox{
        width: 19.5vw;
        margin-left: 10px;
        border: solid white 1px;
    }
    @media only screen and (min-width: 1750px ) {
        .duplexBox {
            width: 22vw;
            margin-left: 20px;
            border: solid #ffffff 1px;
        }
        
    }
    .diagramBox{
        background-color:var(--kpis_background);
        padding: 0px ;
        min-width: 25vw;
        border-Radius: 14px;   
    }
    .kpis-table-container  table tbody tr td {
        padding: 1px;
    }
    .kpis-table-container{
        background-color:var(--kpis_background);
    width: 30vw;
        overflow:hidden;
    }
    .kpis-table-container span.singleContextTitles{
        min-width: 230px;
        word-break: keep-all;
        
    }
    .kpis-table-container table {
        width: 28vw;
        text-align: left;
        overflow: hidden;
    }

    .kpis-table-container tr:hover {
        background-color:var(--kpis_background) !important;
    }
    .kpis-table-borders, .kpis-table-borders tr th, .kpis-table-borders tr td{
        border:solid 1px var(--white);
        border-collapse: collapse;
        color:#fff;
        text-align: center;
        height: 25px;
    }

    .kpis-table-borders thead, .kpis-table-borders thead tr th {
        background-color: var(--kpis_filter);
        color: var(--coral);
        text-align: center;
        padding: 2px !important;
    }
    .loadingContainer{
        height: 80vh;
    }
    .kpis-loadingContainer{
        background-color: var(--kpis_background);
        height: 100vh;
    }
    .kpis-main{
        background-color:var(--kpis_background);
        padding:0px 15px 20px;

    overflow:hidden !important ;
    }

    .kpis-main .components-container:last-child {
        margin-top: 40px;
    }
 /*kpis classes*/
/*searchJobs*/
    .serchNavTypeJobsContainer{
        background-color: var(--soft-grey);
        border-radius: 4px;
        margin-top: 8px;
    }
    .serchNavTypeJobsSelect{
        background-color: transparent;
        position: absolute;
        height: 48px;
        outline: 0;
        border: none;
        z-index: 1;
        left: 8px;
    }
    .serchNavTypeJobsInputContainer{
        position: relative;
        margin-left: 146px;
    }
    .serchNavJobsInput{
        width: 100%;
        height: 48px;
        border: none;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        font-size: 16px;
        line-height: 1.5;
        padding: 12px 12px 12px 48px;
        background-color: var(--soft-grey);
        -webkit-appearance: none;
        border: 1px transparent;
        color: var(--greyish-brown);
    }
    .serchNavJobsInput:focus-visible {
        outline: transparent;
        background-color: var(--white);
    }
    .serchNavJobsInput:focus {
        outline: none;
        background-color: var(--white);
        box-shadow: rgb(0 0 0 / 5%) 0px 4px 8px 0px;
        border-color: var(--light-grey);
    }
    .serchNavJobsIconSearch{
        padding: 0;
        background-color: transparent;
        border: none;
        position: absolute;
        top: 50%;
        left: 16px;
        -webkit-transform: translate(0,-50%);
        -ms-transform: translate(0,-50%);
        transform: translate(0,-50%);
    }
/*searchJobs*/
/*searchTable*/
    .table-wrapper{
        padding: 8px 14px 0;
        height: 81vh;
        overflow-y: auto;
    }
    .tableSearch{
        width: 100%;
    }

    .tableSearch thead tr th{
        position: sticky;
        top: -8px; /* Don't forget this, required for the stickiness */
        background-color: var(--white);
        z-index: 9;
    }
    .tableSearch tbody tr:hover,.tableSearch-match tbody tr:hover{
        background-color: var(--soft-grey);
        cursor: pointer;
    }
    .tableSearch td,.tableSearch-match td {
        text-align: center;
    }
    .tableSearch .longText{
        width: 13%;
    }
    .tableSearch .mediumText{
        width: 6%;
    }
    .tableSearch .incidenceRow{
        display: flex;
        flex-wrap: wrap;
        max-width: 50%;
        justify-content: center;
        align-items: center;
        margin: 15px auto 0;
    }
    .noBalance,.balance,.owned {
        margin-top:5px;
        height: 36px;
        font-weight: Bold;
        width: max-content;
        min-width: 115px;
        padding: 0
    }
    .noBalance {
        background-color:var(--kpis_red) !important;
    }
     .balance {
        background-color:var(--kpis_green) !important;
    }
    .owned {
        background-color:var(--coral) !important;
        color: var(--black-wolly) !important;
    }
    .tableSearch .incidence-green,
    .tableSearch .incidence-yellow,
    .tableSearch .incindece-red{
        width: 25px;
        height: 25px;
        border-radius: 50%;
        margin: auto;
    }
    .tableSearch .incidence-green{
        background-color: var(--green);
    }
    .tableSearch .incidence-yellow{
        background-color: var(--coral);
    }
    .tableSearch .incindece-red{
        background-color: var(--kpis_red);
    }
/*searchTable*/
/*helper DOM element in conjunction with images and text*/

.vertical-helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.vertical-center {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.vertical-align-middle > * {
    display: inline-block;
    vertical-align: middle;
}



.display-flex{
    display: flex;
}

.flex-column{
    flex-direction: column !important;
}
.flex-row{
    flex-direction: row !important;
}

.vertical-flex-align-center{
    align-items: center;
}
.vertical-flex-align-around{
    align-items: space-around;
}
.horizontal-flex-justify-center{
    justify-content: space-around;
}
.horizontal-flex-justify-arround{
    justify-content: space-around;
}
.horizontal-flex-justify-between{
    justify-content: space-between;
}
.horizontal-flex-justify-evenly{
    justify-content: space-evenly;
}

.cursor-pointer {
    cursor: pointer;
}

.container-xs {
    margin-right: auto;
    margin-left: auto;
    padding-left: 16px;
    padding-right: 16px;
}
.container-md {
    margin-right: auto;
    margin-left: auto;
}

.st {
    text-decoration: line-through;
}

.bg-medium-turquoise {
    background-color: var(--medium-turquoise);
}
.bg-black-wolly {
    background-color: var(--black-wolly);
}
.bg-black-wolly-darker {
    background-color: var(--black-wolly-darker);
}
.bg-mosque {
    background-color: var(--mosque);
}
.bg-soft-grey {
    background-color: var(--soft-grey);
}
.bg-footer-jobin {
    background-color: var(--var(--black-wolly)-jobin);
}
.bg-black-wolly {
    background-color: var(--black-wolly);
}
.bg-soft-grey {
    background-color: var(--soft-grey);
}
.bg-coral {
    background-color: var(--coral);
}

.color-coral {
    color: var(--coral);
}
.color-white {
    color: var(--white);
}
.color-greyish {
    color: var(--greyish);
}

.color-white h1,
.color-white h2,
.color-white h3,
.color-white h4,
.color-white p,
.color-white li {
    color: var(--white);
}

.coral-fill {
    fill: #ff3569;
}
.white-fill {
    fill: var(--white);
}
.black-wolly-fill {
    fill: var(--black-wolly);
}

.font-size-36px {
    font-size: 36px;
}
.font-size-80px {
    font-size: 80px;
}

.border-radius-4px {
    border-radius: 4px;
}

.grecaptcha-badge {
    display: none !important;
}

/*
grid modifitacions
*/
.container-fluid,
.container {
    padding-left: 24px;
    padding-right: 24px;
}

.row {
    margin-left: -16px;
    margin-right: -16px;
}
.layout {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 1600px;
    padding: 0 30px;
    width: 100%;
}

.col,
.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
    padding-left: 16px;
    padding-right: 16px;
}

/*
inputs
*/
.jobin-app-form-group + .jobin-app-form-group {
    margin-top: 16px;
}
.jobin-app-input-group {
    position: relative;
}
.jobin-app-input-group input:not([type="checkbox"]) {
    width: 100%;
    height: 48px;
    border-radius: 4px;
    background-color: var(--white);
    border: solid 1px var(--white-two);
    box-shadow: none;
    font-size: 15px;
    color: var(--greyish-brown);
    padding-left: 16px;
    padding-right: 40px;
}
.jobin-app-input-group input:focus:not([type="checkbox"]) {
    outline: none;
    border-color: var(--warm-grey);
}
.jobin-app-input-group > svg {
    pointer-events: none;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    left: 16px;
    transform: translate(0, -50%);
}
.jobin-app-input-group input[type="checkbox"] + label {
    cursor: pointer;
    position: absolute;
    position: absolute;
    right: 12px;
    top: 12px;
}

.jobin-app-input-groupv3 input {
    border: none;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--white);
    padding: 20px 24px;
}
.jobin-app-input-groupv3 input:focus {
    outline: none;
}

/*
input search
*/
.input-search-container {
    position: relative;
}
.input-search-container button {
    padding: 0;
    background-color: transparent;
    border: none;
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translate(0, -50%);
}
.input-search-container input {
    width: 100%;
    height: 48px;
    border: none;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    font-size: 16px;
    line-height: 1.5;
    padding: 12px 12px 12px 48px;
    background-color: var(--soft-grey);
    -webkit-appearance: none;
    border: 1px solid transparent;
}
.input-search-container input:focus {
    outline: none;
    background-color: var(--white);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
    border-color: #e8e8e8;
}
.input-search-container input:placeholder,
textarea:placeholder {
    color: var(--greyish);
}
.input-search-container input:focus ~ button svg g.color-fill path {
    fill: var(--greyish-brown);
}

.input-search-container input::-webkit-input-placeholder {
    color: var(--greyish);
}
.input-search-container input:-ms-input-placeholder {
    color: var(--greyish);
}
.input-search-container input::placeholder {
    color: var(--greyish);
}

/*
buttons
*/
.jobin-app-btn-submit {
    width: 100%;
    height: 56px;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 4px;
    color: var(--coral);
    background-color: var(--black-wolly);
    text-align: center;
    margin-top: 28px;
}
.jobin-app-btn-submit:hover {
    cursor: pointer;
    /* background-color: var(--coral); */
}
.jobin-app-btn-submit:focus {
    outline: none;
    border: solid 1px var(--coral);
}

.jobin-landing-cta {
    border: none;
    width: 100%;
    max-height: 56px;
    padding: 17px 24px;
    background-color: var(--coral);
    border-radius: 4px;
    font-size: 17px;
    font-weight: 600;
    color: var(--white);
    border: solid 2px rgba(32, 32, 32, 0.2);
}
.jobin-landing-cta:hover {
    cursor: pointer;
    background-color: var(--coral);
}

.jobin-app-cta {
    padding: 12px 24px;
    display: inline-block;
    color: var(--greyish-brown);
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    border: 2px solid var(--greyish-brown);
    border-radius: 4px;
}

/*
lists with radio buttons
*/
.subvert-list {
    list-style: none;
    padding-left: 32px;
    padding-right: 32px;
    margin-bottom: 0;
    text-align: left;
    margin-top: 40px;
    padding: 0;
}
.subvert-list li + li {
    margin-top: 24px;
}
.subvert-list label {
    position: relative;
    display: block;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: normal;
    cursor: pointer;
}
.subvert-list label > * {
    vertical-align: middle;
}
.subvert-list label svg {
    position: absolute;
    top: 4px;
}
.subvert-list label p {
    font-size: 16px;
    display: inline-block;
    margin: 0;
    margin-left: 32px;
    text-decoration: none;
    color: var(--greyish-brown);
}

/* 
alerts 
*/
.jobin-app-alert {
    font-weight: 600;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    margin-bottom: 16px;
}
.jobin-app-alert.danger {
    background-color: var(--coral);
    color: var(--black-wolly);
}

/* 
warnings
 */
.input-warning,
.warning-on-focus {
    display: block;
    margin-top: 8px;
    font-weight: 600;
    color: var(--coral);
}

/*
navbar
*/
.jobin-app-navbar {
    position: fixed;
    top: 0;
    width: 100%;
    height: 72px;
    z-index: 100;
    padding: 0 24px;
    background-color: var(--white);
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
}
.jobin-app-navbar .navbar-header {
    float: left;
}
.jobin-app-navbar .navbar-brand:hover {
    text-decoration: none;
}
.jobin-app-navbar .navbar-brand img,
.jobin-app-navbar .navbar-brand svg {
    height: 40px;
    vertical-align: middle;
}
.jobin-app-navbar .navbar-header,
.jobin-app-navbar .navbar-collapse {
    line-height: 72px;
}

.jobin-app-navbar .navbar-collapse {
    margin: 0;
    padding-left: 0;
    list-style: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: relative;
    opacity: 1;
}
.jobin-app-navbar .navbar-collapse li.left {
    display: none;
    float: left;
    margin-left: 32px;
}
.jobin-app-navbar .navbar-collapse li.right {
    float: right;
}
.jobin-app-navbar .navbar-collapse > li ul {
    padding-left: 0;
}
.jobin-app-navbar .navbar-collapse li {
    margin-top: 0;
    display: inline-block;
}
.jobin-app-navbar .navbar-collapse li + li {
    padding-left: 32px;
}
.jobin-app-navbar .navbar-collapse a {
    text-decoration: none;
}
.jobin-app-navbar .navbar-collapse a,
.jobin-app-navbar .navbar-collapse button {
    font-size: 14px;
    font-weight: 600;
    color: var(--greyish-brown);
    display: block;
    height: 100%;
}
.jobin-app-navbar .navbar-collapse button {
    border: none;
    background-color: var(--coral);
    color: var(--white);
    border-radius: 4px;
    padding: 16px 20px;
}
.jobin-app-navbar .navbar-collapse button:hover {
    cursor: pointer;
    background-color: var(--coral);
}
.jobin-app-navbar .navbar-collapse button:active {
    background-color: var(--coral);
}
.jobin-app-navbar .navbar-collapse button:focus {
    outline: none;
    border: solid 1px var(--coral);
}
.jobin-app-navbar .navbar-collapse a:hover {
    height: 72px;
    color: var(--greyish-brown);
    border-bottom: solid 2px var(--greyish-brown);
}
.jobin-app-navbar .navbar-collapse .active a {
    height: 72px;
    color: var(--coral);
    border-bottom: solid 2px var(--coral);
}
.jobin-app-navbar .navbar-collapse #upload-application a {
    display: unset;
    background-color: var(--coral);
    color: var(--white);
    height: 40px;
    border-radius: 4px;
    padding: 10px 16px;
}
.jobin-app-navbar .navbar-collapse #upload-application a:hover {
    text-decoration: none;
    background-color: var(--coral);
}
.jobin-app-navbar .navbar-collapse #jobin-business-login a {
    display: unset;
    padding: 10px 16px;
    border-radius: 4px;
    background-color: var(--jobin-business);
    color: var(--white);
    font-weight: 600;
}
.jobin-app-navbar .navbar-collapse #jobin-business-login a:hover {
    background-color: var(--jobin-business-dark);
    text-decoration: none;
}

/*navbar toggle*/
.jobin-app-navbar #toggle-nav {
    display: none;
    position: absolute;
    height: 56px;
    width: 72px;
    top: 0;
    left: 0;
    text-align: right;
    padding: 0;
    outline: none;
    border-width: 0;
    background-color: transparent;
    cursor: pointer;
}
.jobin-app-navbar #toggle-nav img,
.jobin-app-navbar #toggle-nav svg {
    width: 20px;
}
.jobin-app-navbar.collapsed #toggle-nav {
    position: fixed;
    z-index: 200;
}
.jobin-app-navbar.collapsed #toggle-nav img,
.jobin-app-navbar.collapsed #toggle-nav svg {
    transform: rotate(180deg);
}
.jobin-app-navbar.collapsed {
    position: initial !important;
}
.jobin-app-navbar.collapsed .navbar-header {
    position: fixed;
    z-index: 200;
}
.jobin-app-navbar.collapsed .navbar-collapse {
    display: block;
    position: fixed;
    background-color: var(--white);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    overflow: scroll;
}
.jobin-app-navbar.collapsed .navbar-collapse > li {
    font-size: 18px;
    margin-left: 24px;
}
.jobin-app-navbar.collapsed .navbar-collapse > li:first-child {
    margin-top: 100px;
}
.jobin-app-navbar.collapsed .navbar-collapse li {
    display: block;
}
.jobin-app-navbar.collapsed .navbar-collapse li + li {
    padding-left: 0;
}
.jobin-app-navbar.collapsed .navbar-collapse li.left,
.jobin-app-navbar.collapsed .navbar-collapse li.right {
    float: initial;
}

.jobin-app-navbar.collapsed .navbar-collapse .nav-item {
    position: relative;
    padding-left: 40px;
}
.jobin-app-navbar.collapsed .navbar-collapse .nav-item svg {
    position: absolute;
    top: 16px;
    left: 0;
}
.jobin-app-navbar.collapsed .navbar-collapse li.right {
    height: 56px;
}
.jobin-app-navbar.collapsed .navbar-collapse li.right .nav-menu {
    position: relative;
    height: 56px;
    padding-left: 40px;
}
.jobin-app-navbar.collapsed .navbar-collapse li.right .nav-menu > svg {
    position: absolute;
    top: 16px;
    left: 0;
}
.jobin-app-navbar.collapsed .navbar-collapse a:hover {
    height: initial;
    color: var(--coral);
    border: none;
}
.jobin-app-navbar.collapsed .navbar-collapse a:hover ~ svg g path {
    fill: var(--coral);
}
#nav.collapsed .right .nav-menu a.dropdown-toggle:hover,
#nav.collapsed .right .nav-menu button.dropdown-toggle:hover {
    border: none;
}
#nav.collapsed .right .nav-menu .dropdown-menu {
    height: 22px;
    border: none;
    padding: 0;
    box-shadow: none;
    top: 48px;
    left: 40px;
}
#nav.collapsed .right .nav-menu .dropdown-menu li {
    padding: 0;
}
#nav.collapsed .right .nav-menu .dropdown-menu li button {
    font-size: 12px;
    font-weight: normal;
}

/*
footer
*/
.jobin-landing-footer {
    padding: 72px 0;
}
.jobin-landing-footer p.title {
    font-size: 16px;
    color: var(--white);
    margin-bottom: 16px;
}
.jobin-landing-footer ul {
    margin-bottom: 0;
    padding-left: 0;
}
.jobin-landing-footer ul li {
    list-style: none;
    line-height: 22px;
}
.jobin-landing-footer a {
    color: var(--warm-grey);
    text-decoration: none;
}
.jobin-landing-footer a:hover {
    color: var(--white);
}

/*section*/
.section {
    max-height: calc(100vh - 88px);
    overflow: auto;
}

/*
responsive
*/

@media (max-width: 767px) {
    p {
        font-size: 15px;
    }
    h1 {
        font-size: 32px;
    }
    h2 {
        font-size: 24px;
    }
    h3 {
        font-size: 20px;
    }
    h4 {
        font-size: 18px;
    }
    h5 {
        font-size: 15px;
    }
    /*gutter*/
    .margin-top-72px {
        margin-top: 56px;
        margin-bottom: 16px;
    }
    .margin-top-72px > .container {
        padding-left: 24px;
        padding-right: 24px;
    }
    .jobin-app-navbar,
    .layout {
        padding: 0 24px 0 16px;
    }

    /*navbar*/
    .jobin-app-navbar {
        height: 56px;
    }
    .jobin-app-navbar .navbar-header,
    .jobin-app-navbar .navbar-collapse {
        line-height: 56px;
    }
    .jobin-app-navbar .right > ul {
        display: inline-block;
        padding-left: 16px;
    }
    #nav .right .nav-menu button.dropdown-toggle {
        border: none;
        height: 56px;
    }
    .jobin-app-navbar .navbar-header ul.logos {
        padding: 12px 0;
        height: 56px;
    }
    .jobin-app-navbar .navbar-header .logos img {
        height: 100%;
        vertical-align: unset;
    }
    .jobin-app-navbar.collapsed.color-white .navbar-brand svg > g {
        fill: var(--coral);
    }
    .jobin-app-navbar.collapsed.color-white .navbar-collapse a {
        color: var(--greyish-brown);
    }

    /*hide logos when integration appears*/
    .jobin-app-navbar.integrations .navbar-header,
    .jobin-app-navbar.integrations #toggle-nav {
        display: none;
    }

    /*navbar collapse*/
    .navbar-collapse {
        display: none;
    }
    /*navbar*/
    .jobin-app-navbar {
        height: 56px;
    }
    .jobin-app-navbar .navbar-header .navbar-brand {
        display: block;
        width: 32px;
    }
    .jobin-app-navbar .navbar-header,
    .jobin-app-navbar .navbar-collapse {
        line-height: 56px;
    }
    .jobin-app-navbar .navbar-brand-text {
        display: none;
    }
    .jobin-app-navbar {
        padding: 0 24px 0 16px;
    }
    .jobin-app-navbar .navbar-brand img,
    .jobin-app-navbar .navbar-brand svg {
        height: 40px;
    }
    .jobin-app-navbar.collapsed.color-white .navbar-brand svg > g {
        fill: var(--coral);
    }
    .jobin-app-navbar.collapsed.color-white .navbar-collapse a {
        color: var(--greyish-brown);
    }
    .jobin-app-navbar .search-service {
        display: none;
        margin-top: 8px;
        width: calc(100% - 56px);
        margin-left: 24px;
    }
    #service-type .jobin-app-navbar .search-service {
        margin-bottom: 0;
    }
    .jobin-app-navbar .search-service .input-search-container {
        position: relative;
    }
    .jobin-app-navbar .search-service .input-search-container input {
        font-size: 14px;
        padding-left: 40px;
        padding-top: 8px;
        padding-bottom: 8px;
        width: 100%;
        height: 40px;
        border-radius: 4px;
        -webkit-appearance: none;
    }
    .jobin-app-navbar .search-service .input-search-container button {
        transform: translate(0, 0);
        top: 10px;
    }
    .jobin-app-navbar .search-service .input-search-container .search-icon {
        width: 16px;
    }

    /*footer*/
    .jobin-app-footer {
        padding: 32px 0;
        text-align: center;
    }
    .jobin-app-footer .col-xs-12 {
        margin-top: 32px;
    }
    .jobin-app-footer #footer-links {
        margin-top: 24px;
        margin-bottom: 24px;
    }
    .jobin-app-footer #footer-links li {
        display: block;
    }
    .jobin-app-footer #footer-links li + li:before {
        content: none;
    }

    .section {
        max-height: calc(100vh - 72px);
    }

    .subvert-list {
        margin-bottom: 32px;
    }
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .container-md {
        width: 75%;
    }
}
