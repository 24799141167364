.dashboard-content {
    box-sizing: border-box;
    width: calc(100% - 64px);
    margin-left: 64px;
}

.allowedInResponsive{
       display: none;
}



@media only screen and (max-width: 768px) {
    .bannedInResponsive{
        display:none;
     }
     .allowedInResponsive{
            display: flex;
            height: 100%;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            text-align: center
     }
    .dashboard-content {
         box-sizing: border-box;
         width: calc(100% - 64px);
         margin-left: 64px;
     }
   
 }
@media only screen and (max-width: 578px) {

   .dashboard-content {
        box-sizing: border-box;
        width: calc(100% - 64px);
        margin-left: 64px;
    }
}
@media only screen and (max-width: 576px) {
  
    .dashboard-content {
        box-sizing: border-box;
        width:100vw;
        margin-left: 0;
        height: 100vh;

    }
}