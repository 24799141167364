.jobs-map-icon{
    background-color: white;
    border-radius: 100px;
    padding: 2px;
    width: 30px;
    box-shadow: 0px 0px 8px -1px #000000;
}

.b-c-blue{
    border: 3px solid var(--black-wolly-darker);
    box-shadow: 0px 0px 8px -1px var(--black-wolly-darker);
}

.b-c-yellow{
    border: 3px solid var(--coral);
    box-shadow: 0px 0px 8px -1px #000000;
}

.b-c-red{
    border: 3px solid #FF3569;
    box-shadow: 0px 0px 8px -1px #FF3569;
}

.b-c-green{
    border: 3px solid #35FFC8;
    box-shadow: 0px 0px 8px -1px #35FFC8;
}