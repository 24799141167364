.broker-card{
    border: 1px solid var(--greyish);
    box-shadow: 0px 11px 20px 1px rgba(130,130,130,0.67);
    border-radius: 15px;
    width: 100%;
    height: 510px;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.broker-card::-webkit-scrollbar {
    width: 10px;
}

.broker-card::-webkit-scrollbar-track{
    background-color: transparent; 
}

.broker-card::-webkit-scrollbar-thumb {
    background-color: #b3b3b3;
    border-radius: 20px;
}

.broker-title{
    font-weight: bold;
    font-size: 16px;
    margin: 7px 0px;
}

.broker-item{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 35px;
}

.broker-name{
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.broker-item-number{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 35px;
    font-size: 16px;
    font-weight: bold;
    border: 1px solid #b3b3b3;
    border-radius: 25px;
    box-shadow: 0px 11px 20px 1px rgba(130,130,130,0.67);
}

.amount-red{
    background-color: #ff3333;
}

.amount-green{
    background-color: #80ffcc;
}

.broker-item-button{
    background-color: transparent;
    border: none;
    font-size: 28px;
    margin: 0px 5px
}

.broker-amount{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.broker-save{
    margin-top: 25px;
    box-shadow: 0px 11px 20px 1px rgba(130,130,130,0.67);
    background-color: black;
    border-radius: 10px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    width: 180px;
    height: 50px;
}