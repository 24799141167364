.notification-wrapper{
    border-radius: 10px;
    background-color: white;
    border: 1px solid #e0e0e0;
    width: 100%;
}

.space-between{
    margin: 10px;
    display: flex;
    justify-content: space-between;
}