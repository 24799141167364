#service-picture .title {
    margin-top: 48px;
    font-weight: bold;
    margin-bottom: 12px;
}
#service-picture .content {
    position: relative;
}
#service-picture .drop-zone {
    cursor: pointer;
    position: relative;
    margin-top: 24px;
    width: 100%;
    height: 320px;
    border-radius: 4px;
    border: dashed 1px var(--greyish);
}
#service-picture .drop-zone.drag-acept {
    border: dashed 2px var(--black-wolly);
}
#service-picture .drop-zone.drag-reject {
    border: dashed 2px var(--coral);
}
#service-picture .drop-zone .middle-helper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}
#service-picture .drop-zone .middle-helper button {
    cursor: pointer;
    position: relative;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: var(--coral);
    border-color: transparent;
    border-radius: 4px;
    padding: 12px 16px 12px 40px;
}
#service-picture .drop-zone .middle-helper button img {
    position: absolute;
    width: 20px;
    top: 50%;
    transform: translate(0, -50%);
    left: 12px;
}
#service-picture .drop-zone .middle-helper p {
    font-size: 14px;
}

#service-picture aside {
    margin-left: -12px;
    margin-right: -12px;
}
#service-picture .thumb {
    position: relative;
    width: 140px;
    height: 140px;
    display: inline-block;
    margin-left: 12px;
    margin-right: 12px;
}
#service-picture .thumb:hover .img-remove {
    display: inline-block;
}
#service-picture .thumb img {
    border-radius: 4px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    margin: auto;
}
#service-picture .thumb .img-remove {
    position: absolute;
    width: 140px;
    height: 140px;
    display: none;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.2);
}
#service-picture .img-placeholder {
    cursor: pointer;
    position: relative;
    width: 140px;
    height: 140px;
    display: inline-block;
    margin-left: 12px;
    margin-right: 12px;
    border: dashed 1px var(--greyish);
    border-radius: 4px;
}
#service-picture .img-placeholder:hover,
#service-picture .thumb .img-remove:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
#service-picture .img-placeholder img,
#service-picture .thumb .img-remove img {
    cursor: pointer;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#service-picture.files-added.section {
    overflow: unset;
}
#service-picture.files-added .drop-zone {
    position: absolute;
    top: 0;
    margin-top: 0;
    opacity: 0;
    z-index: -1;
}
#service-picture.files-added .drop-zone .middle-helper {
    display: none;
}

/*
responsive
*/
@media (max-width: 767px) {
    #service-picture .title {
        font-size: 22px;
        margin-top: 24px;
    }
    #service-picture .drop-zone .middle-helper {
        width: 200px;
        max-width: 100%;
    }
    #service-picture .drop-zone {
        min-height: 200px;
    }
    #service-picture .thumb,
    #service-picture .thumb .img-remove,
    #service-picture .img-placeholder {
        width: 120px;
        height: 120px;
    }
}
