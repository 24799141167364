.campains-wrapper{
    width: 100%;
    height: 100vh;
}

.campains-filter-title{
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 30px;
    margin-top: 30px;
}

.campains-filter{
    margin-top: 20px;
    margin-bottom: 5px;
}

.campains-total-clicks{
    border: 1px solid var(--greyish);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 4px 8px 0 rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    height: 120px;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.campains-total-clicks-main-number{
    font-size: 40px;
    font-weight: bold;
}

.campains-total-clicks-light-number{
    font-weight: bold;
    font-size: 22px;
}

.red{
    border-color: #fd6868;
}

.blue{
    border-color: #0d83ab;
}

.orange{
    border-color: #faa43a;
}