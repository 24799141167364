.generic-pdf{
    width: 795px;
    padding-right: 48px;
    padding-left: 48px;
    border: 1px solid grey;
    margin-bottom: 20px;
}

.generic-pdf-title-wrapper{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    text-align: end;
}

.generic-pdf-title{
    font-size: 24px;
    font-weight: bold;
}

.generic-pdf-subtitle{
    font-size: 18px;
    font-weight: bold;
    color: #0AAFB4;
}

.generic-pdf-little-box{
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
    padding: 6px;
    width: 25%;
}

.generic-pdf-boxes-wrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.bold{
    font-weight: bold;
}

.generic-pdf-box-border-right{
    border-right: 1px solid grey;
}

.generic-pdf-box-title-wrapper{
    margin-top: 10px;
    width: 100%;
    border: 1px solid grey;
    background-color: #DDDDDD;
}

.generic-pdf-box-title{
    font-weight: bold;
    padding: 6px;
}

.generic-pdf-75-box{
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
    padding: 6px;
    width: 75%;
}

.generic-pdf-medium-box{
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
    padding: 6px;
    width: 50%;
}

.generic-pdf-wolly-logo{
    width: 150px;
    height: 150px;
}

.generic-pdf-generic-logo{
    width: 100px;
    height: 100px;
    margin-top: 20px;
}

.generic-pdf-signature{
    width: 50px;
    height: 50px;
    margin-left: 20px;
}