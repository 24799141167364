.incident-wrapper{
    border-radius: 10px;
    background-color: white;
    border: 1px solid #e0e0e0;
    width: 100%;
}

.all-incident-wrapper{
    border-radius: 10px;
    background-color: white;
    border: 1px solid #e0e0e0;
    width: 47%;
    margin: 10px;
}

.space-between{
    margin: 10px;
    display: flex;
    justify-content: space-between;
}

.incident-typology{
    font-size: 16px;
    font-weight: bold;
    padding-top: 2px;
}

.incident-detail-typology{
    font-size: 24px;
    font-weight: bold;
}

.incident-description{
    font-size: 13px;
    margin-bottom: 20px;
    margin-top: 10px;
}

.incident-createdAt{
    margin-top: 2px;
    color:grey;
    font-size: 13px;
}

.incident-woller{
    margin-top: 2px;
    font-weight: bold;
    color:grey;
    font-size: 14px;
}



.incident-show-more{
    text-decoration: underline;
    margin-top: 10px;
    border:none;
    background-color: white;
    justify-self: center;
    
}

.incident-textarea{
    border-radius: 10px;
    outline: none;
    margin-top: 10px;
    width: 500px;
    height: 50px;
    padding: 0px 20px 0px 20px;
}

.abierto{
    text-align: center;
    padding: 2px 5px 2px 5px;
    border-radius: 5px;
    color:green;
    border: 2px solid green;
    text-transform: capitalize;
    background-color: white;
}

.in-progress{
    text-align: center;
    padding: 2px 5px 2px 5px;
    border-radius: 5px;
    color:#FFE235;
    border: 2px solid #FFE235;
    background-color: white;
    text-transform: capitalize;
}

.cerrada{
    text-align: center;
    padding: 2px 5px 2px 5px;
    border-radius: 5px;
    color:red;
    border: 2px solid red;
    background-color: white;
    text-transform: capitalize;
}

.state-grey{
    padding: 2px 5px 2px 5px;
    border-radius: 5px;
    color:grey;
    border: 2px solid grey;
    text-transform: capitalize;
    background-color: white;
}

.choose-state{
    margin-top: 20px;
    display: flex;
    width: 50%;
    justify-content: space-between;
}

.incident-comment-title{
    margin-top: 20px;
    font-size: 15px;
    font-weight: bold;
}

.incident-save-comment{
    border-radius: 10px;
    padding: 5px 10px 5px 10px;
    margin-left: 20px;
    font-weight: bold;
    outline: none;
}

.incident-comment-wrapper{
    margin-top: 7px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
}

.incident-comment-description{
    padding: 5px;
}

.incident-comment-user{
    padding: 5px;
}

.incident-comment-date{
    padding: 5px;
}

.delete-incident-button{
    outline: none;
    background-color: white;
    padding: 2px 5px 2px 5px;
    border-radius: 5px;
    color:red;
    border: 2px solid red;
}

.back-next-incidence-button{
    outline: none;
    background-color: black;
    color: white;
    border: 1px solid black;
    border-radius: 10px;
    padding: 5px 10px 5px 10px;
}

.incident-count-page{
    margin-top: 2px;
    font-weight: bold;
    color:black;
    font-size: 14px;
}