.userProfile{
    width: 100%;
    height: 100vh;
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
}
.loading{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 992px) {
   .userProfile{
    padding: 10px;
   }
}


@media only screen and (max-width: 576px) {
    .userProfile{
       height: auto;
        flex-direction: column;
        overflow: auto;
        padding: 0;
    }
}