.edit-fixed-price-modal-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 5px;
}
.edit-fixed-price-modal-wrapper p{
   margin: 5px 0;
}

.edit-fixed-price-modal-input{
    margin-top: 70px;
    text-align: center;
    padding: 5px;
}

.edit-fixed-price-modal-button{
    margin-top: 20px;
    font-weight: bold;
    border: 0px;
    background-color: var(--black-wolly);
    padding: 14px;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: 0px;
    opacity: 1;
}