.sliderContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 15vw;
}
.slider {
    -webkit-appearance: none;
    width: 75%;
    height: 3.5px;
    border-radius: 4px;   
    background: var(--black-wolly);
    outline: none;
}
  
.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%; 
    background: var(--black-wolly);
    cursor: pointer;
}
.sliderValue{
    margin-left: 5px;
}