
@font-face {
    font-family: SourceBold;
    src: url("../../assets/fonts/SourceSansProBold.ttf");
    font-weight: bold;
  }
@font-face {
    font-family: Source;
    src: url("../../assets/fonts/SourceSansProRegular.ttf");
  }

.levelModal {
    background: linear-gradient(31deg, #bf953f, #fcf6ba, #b38728, #fbf5b7, #aa771c);
    background-size: 1000% 1000%;

    -webkit-animation: AnimationName 8s ease infinite;
    -moz-animation: AnimationName 8s ease infinite;
    animation: AnimationName 8s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:57% 0%}
    50%{background-position:44% 100%}
    100%{background-position:57% 0%}
}
@-moz-keyframes AnimationName {
    0%{background-position:57% 0%}
    50%{background-position:44% 100%}
    100%{background-position:57% 0%}
}
@keyframes AnimationName {
    0%{background-position:57% 0%}
    50%{background-position:44% 100%}
    100%{background-position:57% 0%}
}

.levelModal{
    width: 65% !important;
    max-width: unset !important;
    border-radius: 25px !important;
    color: var(--black-wolly) !important;
}
.levelModal .banner{
    margin: auto !important;
    width: 75px !important;

}
.levelModal .modalTitle{
    margin: auto;
    font-family: SourceBold;
    font-size: 20px !important;
}
.levelModal .modalSubtitle{
    width: 45%;
    text-align: center;
    color: var(--black-wolly) !important;
    margin: 10px auto 0;
    font-weight: 600;
}

.levelModal .sectionTitle{
    font-family: SourceBold;
    color: var(--black-wolly);
    margin: 10px auto 30px;
    font-size: 15px;
}

.levelModal .buttonDocument{
    width: 22vw;
    height: 50px;
    padding: 5px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid var(--black-wolly) 1.5px;
    border-radius: 5px !important;
    margin:  0 auto 20px;
    text-align: center;
    font-weight: 600;
    background: var(--white);
    position: relative;
}
.levelModal .buttonDocument .hiddenInput{
    width: 100%;
    height: 100%;
    display: block;
    opacity: 0;
    cursor: pointer;
    position: absolute;

}
.levelModal .buttonDocument .documentStateButton {
    position: absolute;
    right: -37px;
    top: 50%;
    transform: translateY(-50%);
}
.levelModal .footerText, .levelModal .footerTextClose{
    text-align: center;
    font-weight: 600;
    color: var(--black-wolly);
}
.levelModal .footerTextClose{
    text-decoration: underline;
    margin-top: 10px;
}
.sendDocument{
    background: var(--black-wolly);
    color: var(--coral);
    margin: 10px auto 0;
    width: 22vw;
    height: 50px;
    padding: 5px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px !important;
    text-align: center;
    font-weight: 600;
    position: relative;
}

@media only screen and (max-width: 576px) {
    /* phone */
    .levelModal{
        width: 100% !important;
        max-width: unset !important;
        border-radius: 25px !important;
        color: var(--black-wolly) !important;
        height: 100%;
    }
    .levelModal .buttonDocument {
        width: 85%;
        height: 66px;
        padding: 5px 11px;
        font-size: 12px;
    }
  

    .levelModal .sendDocument {
        width: 100%;
        height: 45px;
        padding: 15px 11px;
        font-size: 12px;
        margin: 20px auto 0;
    }
    .levelModal .modalSubtitle{
        width: 100%;
        text-align: center;
        color: var(--black-wolly) !important;
        margin: 10px auto 0;
        font-weight: 600;
    }
    .buttonsContainer{
        display: flex;
        width: 100%;
        justify-content: space-around;
        flex-direction: column;
    }
}