.infoColumn {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: calc(100vh - 72px - 71px); /* 72px of navbar height & 71px of footer */
    overflow-y: auto;
    background-color: var(--bone-light);
    padding-right: 16px;
    padding-left: 8px;
    padding-bottom: 16px;
}
.infoContainer {
    margin-top: 16px;
    border-radius: 4px;
    box-shadow: 5px 5px 5px 0px var(--soft-grey);
    padding: 24px;
    background-color: #fff;
    border: 1px solid var(--soft-grey);
}

.edit-fixed-price-button{
    margin-top: 25px;
    font-weight: bold;
    border: 0px;
    background-color: var(--black-wolly);
    padding: 10px;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: 0px;
    opacity: 1;
    width: 45%;
}
