.Charts {     
    display: flex;
    align-items: flex-end;
    width: -moz-fit-content;
    width: fit-content;
    justify-content: flex-start;
    border-bottom: solid 1px white;
    padding: 0 0 4px 4px;
    border-left: solid 1px white;
    height: fit-content;
}
.Charts.horizontal {
    display: block;
}
.Charts.horizontal .Charts--serie {
    display: block;
    margin: 0 0 30px 0;
    border: 0;
}
.Charts.horizontal .Charts--serie label {
    position: relative;
    top: auto;
    right: auto;
    left: 0;
    bottom: auto;
    padding: 0 0 10px 0;
}
.Charts--serie {
    height: 100%;
    margin-right: 7px;
    display: flex;
    align-items: flex-end;
    transform-origin: 0 100%;
    animation: slideUp .6s;
    position: relative;
  
}
.Charts--serie.stacked {
    display: block;
}
.Charts--serie label {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -20px;
    font-family: Helvetica, sans-serif;
    font-size: 10px;
    text-align: center;
    color: #808080;
}
.Charts.horizontal .Charts--item {
    display: block;
    border-radius: 0 2px 2px 0;
    margin: 0;
    height: 1em;
}
.Charts.horizontal .Charts--item b {
    position: absolute;
    right: -20px;
    top: .3em;
}
.Charts--item {
    
    display: inline-block;
    margin: 0;
    flex: 1;
    transition: height 1s ease-out, width 1s ease-out;
    position: relative;
    text-align: center;
    border-radius: 2px 2px 0 0;
    max-width: 5px;
}
.Charts--item.layered {
    position: absolute;
    left: 5%;
    right: 5%;
    bottom: 0;
    margin: 0;
}
.Charts--item.layered b {
    position: absolute;
    right: 0;
}
.Charts--item.stacked {
    position: relative;
    display: block;
    border-radius: 0;
}
.Charts--item b {
    position: relative;
    font-family: Helvetica, sans-serif;
    font-size: 8.5px;
    top: -20px;
    color: #43A19E;
    left:-1px
}
.Legend--color {
    display: inline-block;
    vertical-align: middle;
    border-radius: 2px;
    width: 16px;
    height: 16px;
}
.Legend--label {
    display: inline-block;
    vertical-align: middle;
    font-family: Helvetica, sans-serif;
    font-size: 12px;
    margin: 0 0 0 5px;
}
/* @keyframes slideUp {
    from {
        transform: scaleY(0);
   }
    to {
        transform: scaleY(1);
   }
} */
