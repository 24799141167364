@font-face {
    font-family: SourceBold;
    src: url("../../assets/fonts/SourceSansProBold.ttf");
    font-weight: bold;
  }
@font-face {
    font-family: Source;
    src: url("../../assets/fonts/SourceSansProRegular.ttf");
  }
.jobCardContainer .jobContainer{
    width: 100%;
    max-width: unset;
    display: flex;
    flex-direction: row;
    justify-content: start;
    height: 280px;
    border: solid 1px var(--greyish);
    border-radius: 25px;
    margin-left: 0;
    position: relative;
}
.jobCardContainer .jobContainer .badge{
    width: 50px;
    height: 50px;
    top: 5px;
    left: 5px;
    position: absolute;
}

.noPics{
    height: 250px ;
    width: 250px ;
    background-position: center ;
    background-repeat: no-repeat ;
    background-size: cover ;
    min-width: 250px;
    border-radius: 25px;
    margin-right: 25px;
}
.swiper {
    margin-left: unset;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
    display: block;
    width: 250px;    
    margin-right: 25px;
    min-width: 250px;
    border-radius: 25px;
}
.jobCardContainer .swiper .swiper-slide.jobSlideImage  div{
    height: 250px;
    width: 250px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-width: 250px;
    margin-right: 25px;
}
.swiper-button-next:after, .swiper-button-prev:after {
    font-family: swiper-icons;
    font-size:unset;
    text-transform: none!important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
    color: white;
    font-weight: bolder;
}
.textImage.more {
  cursor: pointer;
}
.textImage.descriptionContainer {
  width: 100%;
}
.dataContainer > .textContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 31vw;
}
.textImage.descriptionContainer .levelAndDescriptionContainer{
  width: 73%;
}
.textImage.descriptionContainer .description{
    font-family: "SourceBold";
    font-size: 20px;
    color: var(--black-wolly);
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}
.textImage.descriptionContainer .verticalImg{
    width: 40px;
    height: 40px;
}

.textImage.descriptionContainer .Oro{
    font-family: "SourceBold";
    text-transform: uppercase;
    color:var(--new-gold) !important;
}

.textImage.descriptionContainer .Platino{
    font-family: "SourceBold";
    text-transform: uppercase;
}

.textImage.descriptionContainer .Plata{
    font-family: "SourceBold";
    text-transform: uppercase;
    color:var(--warm-grey) !important;
}

.textImage.descriptionContainer .Bronce{
    font-family: "SourceBold";
    text-transform: uppercase;
    color:var(--bronze) !important;
}

.textImage.descriptionContainer .businessImg{
    width: 50px;
    height: 50px;
    margin-left: auto;
}

.jobCardContainer .textImage{
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 10px;
}
.jobCardContainer .textImage img{
    width: 20px;
    height: 20px;
    margin-right: 15px;
}
.slotsContainer{
    display: flex;
    align-items: center;
}
.slotsContainer p{
    font-family: "SourceBold";
    color: var(--black-wolly);
    font-size: 13px;
}

.helmet{
    border: dashed 1px var(--greyish-brown);
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 5px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;

}
.helmet img{
    height: 30px;
    width: 30px;
    
}
.empty{
    border: dashed 1px var(--greyish-brown);
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 5px;
}
.priceAndSlots{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: auto;
}
.oneSlot{
    height: 35px;
    width: 35px;
    margin-right: 10px;
}
.priceAndSlots .priceButton{
    padding: 10px 25px;
    border-radius: 8px;
    background-color: var(--black-wolly-darker);
    color: var(--white);
    box-shadow: -1px 2px 7px -1px var(--black-wolly);
    border: none;
    font-family: "SourceBold";
    font-size: 13px;
}
.dataContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}
.mobileSwiper{
    display: none;
}
.mobileNoPic{
    display: none;
}

.campain-button{
    width: 110px;
    height: 35px;
    background-image: url("../../assets/campains/samsung.png");
    background-size: 110px;
    background-repeat: no-repeat;
    background-color: white;
    background-position: left;
    border-radius: 8px;
    box-shadow: -1px 2px 7px -1px var(--black-wolly);
}

.buttons-wrapper{
    display: flex;
    flex-direction: column;
    margin-top: -15px;
}

.campain-wrapper{
    display: flex;
    flex-direction:row;
    justify-content: flex-end;
    margin-top: 10px;
}

.campain-p{
    width: 40%;
    font-size: 12px;
    margin-right: 20px;
}

.mt-20{
    margin-top: -20px;
}
@media only screen and (max-width: 1470px) {
    /* tablet */
    .noPics {
        height: unset;
        width: 200px;
        min-width: 200px;
        margin-right: 18px;
    }
    .swiper {
        height: unset;
        width: 200px;
        min-width: 200px;
        margin-right: 18px;
    }
    .textImage.descriptionContainer .description {
        width: 24vw;
        font-size: 16px;
        max-width: unset;
    }
    .oneSlot {
        height: 30px;
        width: 30px;
        margin-right: 5px;
    }
    .slotsContainer p{
        font-size: 12px;
        letter-spacing: -0.6px;
    }
    .priceAndSlots .priceButton {
        padding: 10px 20px;
    }
    .jobCardContainer .jobContainer {
        max-height: 320px;
        height: auto;
    }
}

@media only screen and (max-width: 1170px) {
    /* tablet */
    .noPics {
        height: unset;
        width: 200px;
        min-width: 200px;
        margin-right: 18px;
    }
    .swiper {
        height: unset;
        width: 200px;
        min-width: 200px;
        margin-right: 18px;
    }
    .textImage.descriptionContainer .description {
        width: 21vw;
        font-size: 16px;
    }
    .oneSlot {
        height: 30px;
        width: 30px;
        margin-right: 5px;
    }
    .slotsContainer p{
        font-size: 12px;
        letter-spacing: -0.6px;
        width: 100px;
    }
    .priceAndSlots .priceButton {
        padding: 10px 20px;
    }
    .jobCardContainer .jobContainer {
        max-height: 320px;
        height: auto;
    }
    .priceAndSlots {
       
        width: 30vw;
       
    }
}
@media only screen and (max-width: 576px) {

    .card{
        margin: 0 0 30px;
    }
    /* phone */
    .jobCardContainer {
        width: 100%;
        max-width: unset;
        display: flex;
        flex-direction: column;
        padding:  0 10px;
        min-height: 78vh;
        margin-top: 10px;
        
    }
    .jobCardContainer .jobContainer .badge{
   display: none;
    }
    .jobContainer{
        height: auto !important;
        padding:20px 10px ;
        max-height: unset !important;
        position: relative;
    }
    .noPics{
       display: none;
    }
    .swiper {
        display: none;
    }
    .dataContainer > .textContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 100%;
    }
    .jobInfoContainer{
        width: 60%;
    }
    .jobCardContainer .textImage.descriptionContainer {
        width: 100%;
 
    }
    .jobCardContainer .textImage {
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 12px;
        font-size: 12px;
    }
    .jobCardContainer .textImage p{
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
     

    .textImage.descriptionContainer .description {
        font-size: 15px;
        width: 44vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .textImage.descriptionContainer .verticalImg {
        width: 35px;
        height: 35px;
    }
    
    .oneSlot {
        height: 30px;
        width: 30px;
        margin-right: 5px;
    }   
    .slotsContainer p{
        font-size: 12px;
    }
    .helmet {
        height: 35px;
        width: 35px;
    }
    .helmet img {
        height: 25px;
        width: 25px;
    }
    .priceAndSlots  {
        margin-top: 20px;
        width: 100%;
    }
    .priceAndSlots .priceButton {
        font-size: 12px;
        padding: 12px 10px 10px 10px;
    }
    .mobileSwiper{
        display: flex;
        height: 100px !important;
        width: 100px !important;
        min-width: unset !important;
        margin-right: 0 !important;
    }
    .mobileNoPic{
        display: flex;
        height: 100px !important;
        width: 100px !important;
        min-width: unset !important;
        margin-right: 0 !important;
    }

    .jobCardContainer .jobContainer {
        max-height: 320px;
        height: auto;
        padding: 20px 25px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .textImage.descriptionContainer .businessImg{
        width: 35px;
        height: 35px;
        margin-left: auto;
        margin-right: 0px;
    }

    .buttons-wrapper{
        width: 55%;
    }

    .campain-button{
        width: 90px;
        height: 35px;
        background-image: url("../../assets/campains/samsung.png");
        background-size: 90px;
        background-repeat: no-repeat;
        background-color: white;
        background-position: left;
    }
}
