
@font-face {
    font-family: Source;
    src: url("../assets/fonts/SourceSansProBold.ttf");
    font-weight: bold;
  }
@font-face {
    font-family: Source;
    src: url("../assets/fonts/SourceSansProRegular.ttf");
  }
#side-menu {
    position: fixed;
    width: 64px;
    height: 100%;
    background-color: var(--black-wolly);
    text-align: center;
    z-index: 50;
}
#side-menu #top-ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    padding-top: 10px;
    max-height: 100vh;
    overflow: auto;
    cursor: pointer;
    height: 80vh
}
@media only screen and (max-width: 1400px) {
    #side-menu #top-ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        padding-top: 10px;
        max-height: 100vh;
        overflow: auto;
        cursor: pointer;
        height: unset;
    }
}
#side-menu #bottom-ul {
    padding-left: 0;
    height: 20%;
    padding-bottom: 46px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}
#side-menu ul li + li {
    margin-top: 12px;
}
#side-menu a {
    position: relative;
    display: block;
    margin: auto;
    width: 50px;
    height: 50px;
    transition: border 0.2s

}
#side-menu a.homeButton:hover {
    border: unset;
    transition: unset;
}
#side-menu a:hover {
    background-color:black;
    border-radius: 50%;
    border: 2.5px solid #fff;
    transition: border 0.2s

}
#side-menu a.active {
    background-color: var(--black-wolly);
    border: 2.5px solid var(--coral);
    border-radius: 50%;
    transition: border 0.2s

}
#side-menu svg,
#side-menu img {
    fill: #7a1616;
    position: absolute;
    width: 24px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
#side-menu #home-logo {
    margin-bottom: 24px;
}
#side-menu #home-logo #wolly-logo {
    width: 50px;
    position: initial;
    transform: none;
}
@media only screen and (min-width: 578px) {

    #menuToggle,   #menu{
        display: none;
    }
}
@media only screen and (max-width: 576px) {
    /* phone */
    #side-menu {
        display: none;
    }
    #menuToggle{
        display: flex;
        position: sticky;
        z-index: 101;
        -webkit-user-select: none;
        user-select: none;
        background-color: var(--black-wolly);
        height: 64px;
        align-items: center;
        width: 100vw;
        margin: 0;
        padding: 0 15px;
        top: -1px;
    }
    #menuToggle a{
        text-decoration: none;
        color: #232323;
        transition: color 0.3s ease;
        display: flex;
        align-items: center;
    
    }
    #menuToggle a img{
        width: 25px;
        margin-right: 10px;
        margin-bottom: 13px;
    }

    #menuToggle a li{
        margin-bottom: 15px;
    }
    #menuToggle a:hover{
        color: tomato;
    }
    #menuToggle input{
        display: block;
        width: 50px;
        height: 40px;
        position: absolute;
        top: 10px;
        left: -7px;
        cursor: pointer;
        opacity: 0; /* hide this */
        z-index: 102; /* and place it over the hamburger */
        -webkit-touch-callout: none;
    }
    #hambContainer{
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        height: 64px;
        z-index: 101;
    }
   #menuToggle .dataContainer{
        margin-left: 5vw;
    }
   #menuToggle .dataContainer .njobs{
        color: var(--white);
    }
   #menuToggle .dataContainer  .customZone{
        color: var(--coral);
    }
    .balanceButton{
      margin-left: auto;  
      padding: 5px 25px;
      border-radius: 8px;
      background-color: var(--black-wolly-darker);
      color: var(--white);
      box-shadow: none;
      border: none;
    }
    .balanceButton.infinite{
        margin-left: auto;
        padding: 20px 15px;
        font-family: "Source";
        border-radius: 8px;
        font-size: 50px;
        background-color: var(--black-wolly-darker);
        color: var(--white);
        box-shadow: none;
        height: 23px;
        border: none;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

    }
    #menuToggle span{
        display: block;
        width: 33px;
        height: 4px;
        margin-bottom: 5px;
        position: relative;
        background: var(--white);
        border-radius: 3px;
        z-index: 101;
        transform-origin: 4px 0px;
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                    background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                    opacity 0.55s ease;
    }

    #menuToggle span:first-child{
        transform-origin: 0% 0%;
    }

    #menuToggle span:nth-last-child(2){
        transform-origin: 0% 100%;
    }

    /* 
    * Transform all the slices of hamburger
    * into a crossmark.
    */
    #menuToggle #hambContainer input:checked ~ span{
        opacity: 1;
        transform: rotate(45deg) translate(-2px, -1px);
        background: #232323;
    }

    /*
    * But let's hide the middle one.
    */
    #menuToggle #hambContainer input:checked ~ span:nth-last-child(3){
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
    }

    /*
    * Ohyeah and the last one should go the other direction
    */
    #menuToggle #hambContainer input:checked ~ span:nth-last-child(2){
        transform: rotate(-45deg) translate(0, -1px);
    }

    /*
    * Make this absolute positioned
    * at the top left of the screen
    */
    #menu{
        position: absolute;
        width: 50vw;
        height: 100vh;
        padding: 70px 0;
        background: var(--white);
        list-style-type: none;
        -webkit-font-smoothing: antialiased;
        /* to stop flickering of text in safari */
        transform-origin: 0% 0%;
        transform: translate(-120%, 0);
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
        left: -16px;
        top: 0px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 0;
    }
    .menuShadow{
        width: 0vw;
        transition: width 0.8s;
        display: none;

    }
    .menuShadow.active{
        background: var(--background-shadow);
        position: absolute;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 50;
        display: block;

    }

    #menu .link{
       margin: 10px auto 0; 
       width: 40vw;
    }

    #menu .logo{
        width: 100px;
        margin: 10px auto 0;
    }

    /*
    * And let's slide it in from the left
    */
    #menuToggle input:checked ~  ul#menu{
        transform: none;
    }
}

