#service-price .title {
    margin-top: 48px;
    font-weight: bold;
    margin-bottom: 12px;
}

#service-price .add-extras{
    display: flex;
    flex: 1;
    flex-direction: column;
}

#service-price .add-extras .button-view{
    display: flex;
    margin-top: 8px;
}