
.jobs-maps-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.jobs-maps-header{
    height: 10vh;
    width: 95%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.jobs-maps-map{
    height: 83vh;
    width: 95%;
}

.jobs-maps-footer{
    height: 7vh;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.jobs-maps-footer-icon{
    width: 20px;
    margin-right: 7px;
}

.jobs-maps-footer-open{
    margin-right: 150px;
}

.jobs-maps-footer-p{
    margin-right: 75px;
}

.jobs-maps-filter-button{
    width: 130px;
    height: 48px;
    border-radius: 4px;
    font-size: 16px;
    line-height: 1.5;
    padding: 12px 12px 12px 12px;
    background-color: rgb(242, 242, 242);
    appearance: none;
    border: 1px solid transparent;
    color: rgb(68, 68, 68);
    font-family: "Source";
    font-weight: bold;
}

.jobs-maps-icon{
    width: 15px;
    height: 15px;
    margin-right: 10px;
    margin-top: 0px;
}

.jobs-map-woller-icon{
    width: 35px;
    height: 35px;
    margin-right: 10px;
    margin-top: 0px;
    border-radius: 50px;
    box-shadow: 0px 0px 15px -1px #000000;
}


.jobs-maps-filter-button-responsive{
    display: none;
}

@media only screen and (max-width: 576px) {
    /* phone */
    .jobs-maps-header{
        display: none;
    }

    .jobs-maps-footer{
        display: none;
    }

    .jobs-maps-map{
        margin-top: 20px;
        z-index: 1;
    }

    .jobs-maps-filter-button-responsive{
        display: inline;
        float: left;
        position: relative;
        z-index: 2;
        margin: 8px;
        width: 110px;
        height: 48px;
        border-radius: 4px;
        font-size: 16px;
        line-height: 1.5;
        padding: 12px 12px 12px 12px;
        background-color: rgb(242, 242, 242);
        appearance: none;
        border: 1px solid transparent;
        color: rgb(68, 68, 68);
        font-family: "Source";
        font-weight: bold;
    }
}