
.dropZone {
    border: 0.2rem dashed var(--black-wolly);
    padding: 2rem;
    border-radius: 0.25rem;
    background-color: #fff;
    text-align: center;
    font-size: 1.5rem;
    transition: 0.25s background-color ease-in-out;
    cursor: pointer;
    display: flex;
    height: 300px;
    justify-content: center;
    align-items: center;

}
.dropZone:hover {
    border: 0.2rem dashed var(--coral);
}


.dropZone.input {
    display: none;
}

  