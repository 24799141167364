.pipedrive-wrapper{
    display: flex;
    width: 100% ;
    padding: 5px 0 5px 24px;
    overflow-x: auto;
    height: calc(100vh - 167px);
}
.loading-container{
    display: flex;
    flex: 1 1 0%;
    background-color: var(--soft-grey);
    justify-content: center;
    align-items: center;
    height: 84vh;
}
.pipedrive-item{
    display: inline-block;
    width: 365px;
    margin-right: 12px;
}
.pipedrive-head{
    display: flex;
    flex: 1 1 0%;
    align-items: center;
    padding: 8px 12px;
    background-color: var(--soft-grey);
    border-radius: 4px;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
}
.pipedrive-column{
    height: 93%;
    background-color: var(--soft-grey);
    border-radius: 4px;
    overflow-y: scroll;
    width: 336px;
}