#login {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: var(--greyish-brown);
    margin: 0;
    overflow: auto;
}

#login ul {
    padding-left: 0;
    list-style-position: inside;
}

#login img#logo {
    margin-top: 70px;
    width: 160px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

#signin-form-container {
    width: 320px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
}

#mail-pass-forgot-group {
    margin-top: 16px;
    margin-bottom: 28px;
    text-align: left;
    font-size: 13px;
    color: var(--warm-grey);
}

#mail-pass-forgot-group p {
    margin: 0;
}

#mail-pass-forgot-group p#p-tech-call {
    color: var(--greyish-brown);
}

.reminder {
    margin-top: 28px;
}

/*
media queries
*/
@media only screen and (max-width: 400px) {
    #signin-form-container {
        width: 100%;
    }
}

@media only screen and (max-height: 600px) {
    img#logo {
        margin-top: 30px;
    }
}