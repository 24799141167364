/*kpis styles*/

.search-nav.iskpis {
   position: static;
   width: 100%;
}
/*kpis styles*/
/*MATCH styles*/

.search-nav.Matchs {
  padding-top: 25px;
  padding-left: 30px;
}
/*MATCH styles*/


.search-nav {
    width: inherit;
    background-color: #fff;
}
.search-nav .above {
    border-bottom: 1px solid var(--soft-grey);
    padding: 8px 0;
}
.search-nav .above .actions {
    display: flex;
    margin-top: 10px;
    justify-content: center;
}
.search-nav .above .input-search-container {
    background-color: var(--soft-grey);
    border-radius: 4px;
    margin-top: 8px;
}
.search-nav .above .input-search-container select {
    position: absolute;
    height: 48px;
    outline: 0;
}
.search-nav .below {
    display: flex;
    border-bottom: 1px solid var(--soft-grey);
    padding: 8px 14px;
    /* overflow-x: auto; */ /*tiene conflictos con los menus */
}
.search-nav .below-left {
    display: flex;
    min-width: 1300px;
    align-items: center;
}

.search-nav .below-right {
    display: flex;
    flex: 1 1 0%;
    justify-content: flex-end;
}

.onlyIncidencesTrue{ 
  cursor: pointer;
  padding: 8px 12px;
  border: transparent;
  background-color: var(--soft-grey);;
  border-radius: 4px;
  font-weight: 600;
  color:  var(--greyish-brown);
  margin-right: 6px;
}

.onlyIncidencesFalse{ 
    padding: 6px 10px 6px 10px;
    border-radius: 5px;
    margin-right: 4px;
    background-color: black;
    color: #FFE235;
    border: 0.5px solid #b0b0b0;
}

.toggleButton{
    position: absolute;
    right: 100px;
    top: 18px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s;
}
.refreshButton{
    position: absolute;
    right: 4px;
    top: 4px;
    background-color: black !important;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s;
}
.toggleButtonActive {
    position: absolute;
    right: 100px;
    top: 20px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    transform: rotate(180deg);
    transition: all 0.3s;
}