@font-face {
    font-family: SourceBold;
    src: url("../../../assets/fonts/SourceSansProBold.ttf");
    font-weight: bold;
  }
@font-face {
    font-family: Source;
    src: url("../../../assets/fonts/SourceSansProRegular.ttf");
  }

.descriptionContainer h3, .descriptionContainer h4{
    font-family: "Source";
    font-weight: lighter;
    color:black !important;
}
.descriptionContainer p{
    letter-spacing: 1px;
    color:black !important;
}
.descriptionContainer span{
    color:black !important;
}
.descriptionContainer.userDetails{
    width: 65%;
    display: flex;
    flex-direction: column;
    padding: 0 25px 25px 25px;
    max-height: unset;
    height: -webkit-fill-available;
    font-family: 'Source';
    margin-left: 25px;
}
.descriptionContainer.userDetails  .title{
   margin: 10px 0 10px;
}
.descriptionContainer.userDetails  .editProfile{
    width: 150px;
    padding: 10px;
    background-color: var(--white);
    border: solid 1px var(--warm-grey);
    border-radius: 10px;
    cursor: pointer;
}
.descriptionContainer.userDetails .basicInformation{
    height: 185px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 20px;
}
.basicInformation .basicInfo{
    width: 45%;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    
}
.basicInformation .basicInfo img{
    width: 25px;
    margin-right: 10px;

}
.descriptionContainer .tasksContainer{
    width: 100%;
    height: auto;
    padding: 10px 10px 10px 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    transition: all 0.3s;
}
.descriptionContainer .individualTaskContainer.external{
    width: 55px;
    height: 55px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: solid 1px var(--warm-grey);
    border-radius: 10px;
    margin: 3px;
    cursor: pointer;
}
.descriptionContainer .individualTaskContainer{
    width: 115px;
    height: 115px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: solid 1px var(--warm-grey);
    border-radius: 10px;
    margin: 4px;
    cursor: pointer;
}
.descriptionContainer .individualTaskContainer p{
   font-size: 10px;
   font-weight: 700;
   text-align: center;
}
.descriptionContainer .individualTaskContainer.disabled img{
    filter: grayscale(1);
    transition: all 0.3s;
    cursor: pointer;
}
.descriptionContainer .individualTaskContainer.disabled:hover img{
    filter: grayscale(0);
}

.descriptionContainer img.taskIcon{
    width: 40px;
    height: 40px;
}
.descriptionContainer img.taskIcon.addTask{
    width: 20px;
    height: 20px;
}
.descriptionContainer .moretasks{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    transition: all var(--duration);
    height: 0px;
    border-radius: 10px;
    overflow: hidden;
    padding: 0;
    width: 61vw;
    margin-top: 10px;
    transition: all 0.3s;
    transition-timing-function: cubic-bezier(0, 0.15, 0, 0.8);
}
.descriptionContainer .moretasks.active{
    border: solid 1px var(--warm-grey);
    padding: 10px;
    transition: all 0.3s;
    transition-timing-function: linear;
    height: auto;
}
.descriptionContainer .reviewsContainer {
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-wrap: wrap;
    margin-top: 10px;
}
.reviewsContainer .individualReviewContainer{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 15px;
    border:solid 1px var(--warm-grey);
    border-radius: 10px;
    width: 48%;
    min-height: 180px;
    margin-bottom: 25px;
}
.reviewsContainer .clientProfile{
    display: flex;
    flex-direction: row;
}
 button.showMoreReviews{
    width: 150px;
    padding: 10px;
    background-color: var(--white);
    border: solid 1px var(--warm-grey);
    border-radius: 10px;
}
.reviewsContainer .clientProfilePic{
    width: 50px;
    height: 50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 10px;
    border: solid 1px var(--warm-grey);
    border-radius: 50%;
}
.photosContainer.userDetails{
    display: flex;
    padding-bottom: 40px;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 10px;
} 
.photosContainer .addPhoto{
    display: flex;
} 
.photosContainer .individualPhoto{
    width: 150px;
    height: 150px;
    background-position: center;
    background-repeat: no-repeat;
    border: solid 1px var(--warm-grey);
    border-radius: 10px;
    position: relative;
    background-size: cover;
    margin-right: 10px;
    margin-bottom: 10px;
}
.photos-wrapper::after {
    content: "X";
    position: relative;
    width: 21px;
    height: 15px;
    bottom: 90%;
    cursor: pointer;
    border-radius: 50%;
    left: 80%;
    font-size: 20px;
    display: block;
}

.editBiPRoModalContainer .inputsContainer{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    height:50vh ;

}
.inputsContainer .basicInfo{
    display: flex;
    align-items: center;
    width: 90%;
    margin-left: 45px;
}
.inputsContainer .basicInfo span{
   font-family: "SourceBold";
   width: 35%;
}
.inputsContainer .basicInfo input{
    width: 40%;
    padding: 5px 10px;
}
.inputsContainer .basicInfo input:nth-child(1n+3){
  text-align: center;
}
.inputsContainer .basicInfo img{
    width: 40px;
    height: 40px;
    margin-right: 10px;

}
.editBiPRoModalContainer .buttonModal{
    width: 150px;
    padding: 10px;
    background-color: var(--white);
    border: solid 1px var(--warm-grey);
    border-radius: 10px;
    cursor: pointer;
}


@media only screen and (max-width: 1170px) {
    /* tablet */
    .photosContainer .individualPhoto {
        width: 12.5vw;
        height: 12.5vw;
       
    }
}

/*media queries*/
@media only screen and (max-width: 992px) {
    /*tablet*/
    .descriptionContainer.userDetails{
        width: 65%;
    }
    .descriptionContainer.userDetails .basicInformation{
        height: 200px;
    }
    .basicInformation .basicInfo{
        width: 48%;
    }
    .basicInformation .basicInfo img{
        width: 25px;
    }
    .descriptionContainer .individualTaskContainer.external{
        width: 80px;
        height: 80px;
        
    }
    .descriptionContainer .individualTaskContainer{
        height: 90px;
        width: 90px;
    }
    .descriptionContainer .individualTaskContainer p{
        font-size: 9px;
    }
    .descriptionContainer .individualTaskContainer.disabled img{
        height: 60px;
        width: 60px;
    }
    .descriptionContainer img.taskIcon{
        height: 60px;
        width: 60px;
    }
    .descriptionContainer .moretasks{
        justify-content: space-around;
    }
   
    .photosContainer.userDetails{
        justify-content: flex-start;
        flex-wrap: wrap;
    } 
    .photosContainer .individualPhoto{
        width: 90px;
        height: 90px;
        background-size: cover;
        margin-left: 10px;
    }
   
}
@media only screen and (max-width: 576px) {
    /* phone */
    .detailsContainer .profileInfo.card {
        margin-top: 30px;
        height: 30vh;

    }
    .profileInfo .personalInfo .namePro {
        margin-top: 20px;
    }
     .descriptionContainer.userDetails  .editProfile{
        display: none;
     }
     .descriptionContainer.userDetails {
        width: 100%;
        padding: 0 30px 15px;
    }     
    .reviewsContainer .clientProfile {
        width: 70vw;
    }
    .reviewsContainer .clientProfile .userProfileData{
        width: 77%;
    }
    .basicInformation .basicInfo{
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }
    .descriptionContainer.userDetails .basicInformation {
        height: auto;
        margin-top: 0;
    }
    .tasksContainer.userDetails{
        padding: 0;
    }
    .descriptionContainer .individualTaskContainer.external {
        width: 52px;
        height: 55px;
        margin-right: 5px;
        margin-bottom: 5px;
    }

    .descriptionContainer img.taskIcon {
        height: 40px;
        width: 40px;
    }
    .descriptionContainer .moretasks{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        transition: all var(--duration);
        height: 0px;
        border-radius: 10px;
        overflow: hidden;
        padding: 0;
        width: 100%;
        margin-top: 10px;
        transition: all 0.3s;
        transition-timing-function: cubic-bezier(0, 0.15, 0, 0.8);
    }
    .descriptionContainer .moretasks.active{
        border: solid 1px var(--warm-grey);
        padding: 10px;
        transition: all 0.3s;
        transition-timing-function: linear;
        height: auto;
    }
    .descriptionContainer .individualTaskContainer{
        width: 75px;
        height: 75px;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: solid 1px var(--warm-grey);
        border-radius: 10px;
        margin: 4px;
        cursor: pointer;
    }
    .descriptionContainer .individualTaskContainer p {
        font-size: 7px;
    }

    .descriptionContainer .reviewsContainer {
        width: 84vw;
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        height: 26vh;
        flex-wrap: nowrap;
    }
    .reviewsContainer .individualReviewContainer {
        width: 75vw;
        margin-right: 10px;
        margin-bottom: 0;
        height: 200px;
    }
    button.showMoreReviews{
        width: 100%;
        padding: 15px 10px;
    }
    .photosContainer.userDetails {
        justify-content: flex-start;
        flex-wrap: nowrap;
        overflow-x: auto;
        width: 85vw;
        height: 350px;
    }
    .photosContainer .individualPhoto {
        width: 300px;
        height: 300px;
        background-size: cover;
        margin-left: 10px;
    }
   
    .photos-wrapper::after {
        content: "X";
        position: relative;
        width: 21px;
        height: 15px;
        bottom: 100%;
        cursor: pointer;
        border-radius: 50%;
        left: 88%;
        font-size: 20px;
        display: block;
    }
    .descriptionContainer h3, .descriptionContainer h4{
        font-family: "Source";
        color:black !important;
        font-weight: 300;
    }
    .descriptionContainer img.taskIcon.addTask {
        width: 20px;
        height: 20px;
    
    }
    .dropZone{
        border: transparent !important;
    }
}

