#service-type .service-item {
    width: 100%;
    display: inline-block; /* to fix the problem with column breaking inner content */
    margin-bottom: 32px;
    position: relative;
}
#service-type .service-item:nth-child(even) {
    grid-column: 2;
}
#service-type .service-item .img-container {
    position: absolute;
}
#service-type .service-item .img-container img {
    width: 40px;
    height: 40px;
}
#service-type .service-item .excerpt {
    margin-left: 48px;
}
#service-type .service-item .excerpt .title {
    font-family: 'Source', sans-serif;
    font-size: 18px;
    font-weight: 600;
    margin: 8px;
}
#service-type .service-item .excerpt .title a {
    display: block;
    text-decoration: none;
    color: var(--greyish-brown);
    border-radius: 4px;
    margin: -8px;
    padding: 8px;
}
#service-type .service-item .excerpt .title a:hover {
    background-color: var(--soft-grey);
}

#service-type .service-item.custom h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.3;
}
#service-type .service-item.custom p {
    font-size: 14px;
    margin-bottom: 32px;
}
#service-type .service-item.custom .img-container {
    bottom: 0;
}

/*
responsive
*/
@media (max-width: 767px) {
    #service-type .service-item {
        margin-bottom: 40px;
    }
    #service-type .service-item .excerpt {
        margin-left: 0;
    }
    #service-type .service-item .excerpt .title {
        margin-top: 8px;
        margin-bottom: 8px;
        margin-left: 48px;
    }
    #service-type .service-item .child-service-list li a {
        font-size: 15px;
        padding-left: 0;
    }

    #service-type .service-item.custom h3 {
        margin-top: 0;
    }
}