#service-description .title {
    margin-top: 48px;
    font-weight: bold;
    margin-bottom: 12px;
}
#service-description p {
    margin-bottom: 0;
}
#service-description .textarea {
    line-height: 1.4;
    margin-top: 24px;
    width: 100%;
    max-width: 100%;
    padding: 16px;
    height: 236px;
    max-height: 100vh;
    border-radius: 4px;
    border: solid 1px #d2d2d2;
    -webkit-appearance: none;
}
#service-description .textarea:focus {
    outline: none;
    border: solid 1px var(--greyish-brown);
}
#service-description span {
    font-size: 13px;
}
#service-description span.primary {
    color: var(--warm-grey);
}
#service-description span.success {
    color: var(--black-wolly-darker);
}
#service-description span.error {
    color: var(--coral);
}

/*
responsive
*/
@media (max-width: 767px) {
    #service-description .title {
        font-size: 22px;
        margin-top: 24px;
    }
    #service-description .textarea {
        font-size: 16px;
        height: 160px;
    }
}
