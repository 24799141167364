#service-summary .title {
    margin-top: 40px;
    font-weight: bold;
    margin-bottom: 12px;
}
#service-summary .excerpt {
    margin-bottom: 40px;
}
#service-completed .step-tip .excerpt {
    margin-bottom: 0;
}

#service-completed .help {
    padding: 24px;
    border-radius: 4px;
    border: solid 1px #e8e8e8;
    background-color: #f8f7f5;
    margin-bottom: 24px;
}
#service-completed .help p {
    font-size: 14px;
    color: var(--warm-grey);
    margin-bottom: 0;
}
#service-completed .help .title {
    font-size: 18px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 12px;
}
#service-completed .help .excerpt {
    font-size: 16px;
    margin-bottom: 6px;
}

#service-completed .first-time {
    border-radius: 4px;
    border: solid 1px #e8e8e8;
    padding: 24px;
    margin-bottom: 24px;
}
#service-completed .first-time .title {
    margin-top: 0;
    font-size: 18px;
    font-weight: 600;
}
#service-completed .first-time .excerpt {
    margin-bottom: 8px;
}
#service-completed .first-time-item {
    width: 100%;
    display: inline-block; /* to fix the problem with column breaking inner content */
    margin-top: 32px;
}
#service-completed .first-time-item .img-container {
    position: absolute;
}
#service-completed .first-time-item .img-container img {
    width: 40px;
    height: 40px;
}
#service-completed .first-time-item .excerpt {
    margin-top: 8px;
    margin-bottom: 0;
    margin-left: 56px;
}
#service-completed .first-time-item .excerpt .title {
    font-size: 16px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 8px;
}
#service-completed .first-time-item p {
    font-size: 14px;
    color: var(--warm-grey);
    margin: 0;
}
#service-completed .btn-back {
    display: none;
}

/*
responsive
*/
@media (max-width: 767px) {
    #service-completed .title {
        font-size: 22px;
        margin-top: 24px;
    }
    #service-completed .excerpt {
        margin-bottom: 24px;
    }
    #service-completed .first-time {
        padding: 0;
        border: none;
        border-top: 1px solid #e8e8e8;
        border-radius: 0;
        padding-top: 24px;
    }
    #service-completed .help {
        margin-left: -24px;
        margin-right: -24px;
        border: none;
        border-radius: 0;
    }
}
