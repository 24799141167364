.child-service-list {
    padding-left: 0;
    margin: 0;
}
.child-service-list li {
    list-style: none;
}
.child-service-list li + li {
    margin-top: 6px;
}
.child-service-list li a {
    font-size: 16px;
    text-decoration: none;
    display: block;
    color: var(--greyish-brown);
    line-height: 1.6;
    padding: 4px 8px;
    border-radius: 4px;
}
.child-service-list li a:hover {
    background-color: var(--soft-grey);
}
