.jobDetailColumn {
    display: flex;
    flex: 1;
    height: calc(100vh - 143px);
    overflow-y: auto;
    flex-direction: column;
    border-right: 2px solid var(--soft-grey);
    position: relative;
}

.jobDetailContainer {
    padding: 32px;
    border-top: 2px solid var(--soft-grey);
}

.jobTypes {
    text-align: center;
    padding: 5px 5px 5px 0px;
    margin: 5px 0px 5px 0px;
    border: 1px solid black;
    border-radius: 10px;
    border-color: #b0b0b0;
}

.editJobTypesButton {
    border: none;
    color: rgb(53, 67, 255);
    font-weight: bold;
    background-color: transparent;
    justify-content: flex-end;
}

.jobTypesWrapper {
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/*homologation*/

.jobDetailColumn .documentContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 15px;
}


.jobDetailColumn .imageContainer {
    display: flex;
    justify-content: normal;
    width: 100%;
    margin-bottom: 15px;
}

.jobDetailColumn .documentMini {
    width: 150px;
    height: 150px;
    margin-right: 15px;
}