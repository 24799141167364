
@font-face {
    font-family: SourceBold;
    src: url("../../assets/fonts/SourceSansProBold.ttf");
    font-weight: bold;
  }
@font-face {
    font-family: Source;
    src: url("../../assets/fonts/SourceSansProRegular.ttf");
  }
.exploreContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 25px 70px;
    position: relative;
    font-family: Source;
    color: var(--black-wolly) !important;
}
.headerInfo{
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 30px;

}
.balanceButton{
    padding: 8px 25px;
    border-radius: 8px;
    background-color: var(--black-wolly-darker);
    color: var(--white);
    box-shadow: -1px 2px 7px -1px var(--black-wolly);
    border: none;
    font-family: SourceBold;
}
.headerInfo p:nth-child(2){
    margin-left: auto;
    margin-right: 10px;
}
.headerInfo p:first-child{
    font-family: SourceBold;
    font-size: 18px;
}
.filltersContainer{
    width: 60%;
}

.moneyModal{
    background: var(--black-wolly);
    width: 65% !important;
    max-width: unset;
    border-radius: 38px !important;
    color: var(--white);
    padding-bottom: 40px;
}
.moneyModal > div{
    display: flex;
    flex-direction: column;
}
.closeButtonMoney svg{
    fill: red;
    margin-right: 5px;
}

.moneyModal .banner{
    width: 95px;
    margin: auto;
}
.moneyModal .modalTitle{
    font-family: SourceBold;
    margin: auto;
    font-size: 24px !important;
    text-align: center;
}
.moneyModal .modalSubtitle{
    text-align: center;
    width: 28%;
    margin: 15px auto;
}
.moneyModal .pricesTitle{
    margin: auto;
    font-family: SourceBold;
    text-align: center;
    color: var(--white);
    font-size: 16px;
}
.moneyModal .packsContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
}
.moneyModal .packsContainer .pack:nth-child(2){
    margin: 0 75px;
}
.packsContainer .pack{
    background: var(--white);
    border-radius: 30px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 19%;
    height: 300px;
    padding: 20px 15px;
    color: var(--black-wolly);
    cursor: pointer;
    transition: all 0.3s;
    border: solid 2px transparent;
}
.packsContainer .pack div{
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
}
.pack:hover{
    border: solid 2px var(--coral);
    box-shadow: var(--golden-shadow);
}
.packsContainer .pack.selected{
   border: solid 2px var(--coral);
   box-shadow:var(--golden-shadow);
   position: relative;
}

.packsContainer .pack.popular{
    position: relative;
}

.packsContainer .popular::after{
    border: solid 2px var(--coral);
    position: absolute;
    content: "MÁS POPULAR";
    font-family: SourceBold;
    top: -21px;
    background: var(--coral);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    text-align: center;
    border-radius: 3px;
    padding: 4px 0;
    font-size: 11px;
}
.pack .imgPack{
    width: 120px;
    height: 120px;
    color: var(--black-wolly);
    
}
.pack .pricePack{
    font-size: 40px;
    font-family: SourceBold;
    color: var(--black-wolly);
}
.pack .ivaPack{
    font-size: 10px;
    margin-top: 0px;
    color: var(--black-wolly);
}
.pack .aprox{
    margin-top: 10px;
    color: var(--black-wolly);
}
.pack .verifiedUsers{
    font-family: SourceBold;
    color: var(--black-wolly);
    text-align: center;
}
.moneyModal .buttonContainer{
    margin: auto;
    display: flex;
    align-items: center;
    margin-top: 30px;
    width: 70%;
    flex-direction: column;
}
.safePayMobile{
    display: none;
}
.safeTextMobile{
    display: none;
}
.moneyModal  .rechargeMoneyButton{
    width: 50%;
    background: var(--coral);
    color: var(--black-wolly);
    border-radius: 10px;
    padding: 15px 30px;
    font-family: SourceBold;
    font-size: 18px;
    margin: 25px auto 0;
}
.moneyModal .buttonContainer img{
    width: 150px;
}
.moneyModal .footerDisclaimer {
    width: 52%;
    text-align: center;
    margin: auto;
    color: var(--white);
    margin-top: 30px;
}

/**/

.outFoundsModal{
    width: 30%;
    border-radius: 38px !important;
    color: var(--black-wolly) !important;
}
.outFoundsModal > div{
    width: 100%;
    display: flex;
    flex-direction: column;
}


.outFoundsModal .banner{
    width: 175px;
    margin: auto;
}
.outFoundsModal .modalTitle{
    font-family: SourceBold;
    margin: 10px auto;
    text-align: center;
}
.outFoundsModal .modalSubtitle{
    font-family: SourceBold;
    margin: 10px auto;
    text-align: center;
}
.outFoundsModal .buttonModal{
    padding: 10px 25px;
    border-radius: 8px;
    background-color: var(--black-wolly-darker);
    color: var(--white);
    box-shadow: -1px 2px 7px -1px var(--black-wolly);
    border: none;
    font-family: SourceBold;
    margin: 10px auto;
    
}
/**/
.insuficentLevelModal{
    width: 30%;
    border-radius: 38px !important;
    color: var(--black-wolly) !important;
}
.insuficentLevelModal > div{
    width: 100%;
    display: flex;
    flex-direction: column;
}


.insuficentLevelModal .banner{
    width: 150px;
    margin: auto;
}
.insuficentLevelModal .modalTitle{
    font-family: Source;
    font-weight: 200 !important;
    margin: 10px auto;
    text-align: center;
}
.insuficentLevelModal .modalSubtitle{
    font-family: SourceBold;
    margin: 10px auto;
    text-align: center;
}
.insuficentLevelModal .buttonModal{
    padding: 10px 25px;
    border-radius: 8px;
    background-color: var(--black-wolly-darker);
    color: var(--white);
    box-shadow: -1px 2px 7px -1px var(--black-wolly);
    border: none;
    font-family: SourceBold;
    margin: 10px auto;
    
}
/**/
.successModal{
    border-radius: 38px !important;
    color: var(--black-wolly) !important;
}
.successModal > div{
    width: 100%;
    display: flex;
    flex-direction: column;
}


.successModal .banner{
    width: 150px;
    margin: auto;
}
.successModal .modalTitle{
    font-family: SourceBold;
    margin: 10px auto;
    text-align: center;
    font-size: 15px !important; 
}
.successModal .modalSubtitle{
    font-family: SourceBold;
    margin: 10px auto;
    text-align: center;
}
.successModal .buttonModal{
    padding: 10px 25px;
    border-radius: 8px;
    background-color: var(--black-wolly-darker);
    color: var(--white);
    box-shadow: -1px 2px 7px -1px var(--black-wolly);
    border: none;
    font-family: SourceBold;
    margin: 10px auto;
    
}

.headerMobile{
    display: none;
}
/*media queries*/
@media only screen and (max-width: 1300px) {
    /*tablet*/
    .filltersContainer{
        width: 65%;
    }
    .exploreContainer{
        padding: 25px 50px;
    }
    .moneyModal .safePayMobile{
        display: none;
    }
}


@media only screen and (max-width: 576px) {
    /* phone */
    .insuficentLevelModal{
       
        width: 100% ;
    }
    .outFoundsModal{
        width: 100% ;
    }
    .successModal{
        width: 100% ;
    }
    .exploreContainer{
        width: 100%;
        display: flex;
        flex-direction: column;
        padding:0;
        position: relative;
        font-family: Source;
    }
    .headerMobile{
        display: flex;
        width: 100%;
        margin-bottom: 5px;
        justify-content: center;
        align-items: center;
        position: sticky;
        top: 63px;
        z-index: 99;
        box-shadow: rgb(0 0 0 / 30%) 0px 5px 5px 0px;

    }   
    .headerMobile .mobieHeaderButton{
        width: 33%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 15px;
        background-color: var(--bone-light);
        border-bottom: 2px solid var(--white-two);
    }
    .headerMobile .mobieHeaderButton.selected{
       font-family: SourceBold;
    }
    .headerMobile .mobieHeaderButton:nth-child(2){
        
        border-left: 2px solid var(--white-two);
        border-right: 2px solid var(--white-two);
    }
    .headerInfo{
        display: none;
    }
    .filltersContainer{
        width: 100%;
    }
    .acordeon{
        z-index: 99;
        display: flex;
        position: absolute;
        flex-direction: column;
        top: 43px;
        background-color: var(--white);
        width: 100%;
        height: 0;
        overflow: hidden;
        transition: height 0.3s, padding-top 0.3s, padding-bottom 0.3s;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 15px;
        padding-right: 15px;

    }
    .acordeon.active{
        padding-top: 10px;
        padding-bottom: 10px;
        height: 65vh;
        border-bottom: solid 1px var(--greyish);
        
    }
    .headerMobile:before{
       transition: opacity 0.3s;
       content: "";
       position: absolute;
       width: 100vw;
       height: 0vh;
       background: var(--background-shadow);
       top: 0;
       z-index: -1;
       opacity: 0;
    }
    .headerMobile.active:before{
        
        height: 100vh;
        opacity: 1;
    }

    .acordeon .select-explore-mobile-wrapper{
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        width: 100%;
        justify-content: space-between;
        align-items: start;
    }
    
    .acordeon .basic-multi-select{
        width: 100%;
    }

    .acordeon.sorters{
        padding: 0;
        flex-direction: row;
    }
    .acordeon.sorters.active{
        height: 50px;
    }
    .acordeon.sorters > div{
        width: 33%;
        margin: 0;
        padding: 10px 30px;
        border: none;
        border-bottom: 2px solid var(--greyish-brown);
        border-radius: 4px;
        color: var(--black-wolly);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--bone-light);
        cursor: pointer;
    }
    .acordeon.sorters > div:nth-child(2){
        
        border-left: 1px solid var(--greyish-brown);
        border-right: 1px solid var(--greyish-brown);
        
    }
    .acordeon.sorters > div.active{
        
        color: var(--bone-light);
        background-color: var(--black-wolly);
        font-family: "SourceBold";
        
    }
    .moneyModal {
        background: var(--black-wolly);
        width: 100% !important;
        max-width: unset;
        border-radius: 38px !important;
        color: var(--white);
        padding-bottom: 40px;
        overflow: auto;
        height: 93vh;
        position: relative;
    }
    .moneyModal > div{
        overflow: auto;
    height: 85vh;
    }
    .moneyModal .moneyOverflow{
        overflow: auto;
        height: 51vh;
    }
    .moneyModal .modalSubtitle {
        width: 100%;
        margin: 10px auto;
    }
    .moneyModal .packsContainer .pack {
        width: 100%;
        height: 140px;
        flex-direction: row;
        padding: 10px 15px;
    }
    .moneyModal .packsContainer .pack:nth-child(2) {
        margin:  35px 0 20px ;
    }
    .pack .aprox {
        font-size: 11px;
        margin-top: 0px;
    }
    .pack .pricePack {
        font-size: 36px;
        line-height: 40px;
    }
    .pack .verifiedUsers {
        font-size: 13px;
        text-align: center;

    }
    .moneyModal .buttonContainer {
        margin-top: 30px;
        flex-direction: column-reverse;
        width: 100%;
    }
    .moneyModal .buttonContainer .rechargeMoneyButton {
        width: 60%;
        margin-left: 0;
    }
    .moneyModal  .rechargeMoneyButton{
        width: 75%;
        background: var(--coral);
        color: var(--black-wolly);
        border-radius: 10px;
        padding: 15px 30px;
        margin-right: 10px;
        font-family: SourceBold;
        font-size: 18px;
        margin-top: 25px;
        position: fixed;
        bottom: 7vh;
        left: 50%;
        transform: translateX(-50%);
    }
    .moneyModal .buttonContainer img {
        display: none;
    }
    .moneyModal .footerDisclaimer {
        width: 100%;
        text-align: center;
        margin: auto;
        color: var(--white);
        margin-bottom: 20px;
    }
    .moneyModal .buttonContainer .safePayMobile{
        display: block;
        margin-bottom: 10px;
    }
    .moneyModal .buttonContainer .safeTextMobile{
        display: block;
        margin-bottom: 40px;
        color: var(--white);
    }
    .moneyModal .packsContainer {
        margin-top: 35px;
        flex-direction: column;
    }
}
