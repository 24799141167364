.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
  }
  
.switch input { 
    opacity: 0;
    width: 0;
    height: 0;
}

.switch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #D5D5D5;
    -webkit-transition: .4s;
    transition: .4s;
}

.switch-slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 3px;
    background-color: var(--bone-light);
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .switch-slider {
    background-color: #1D35F5;
}

input:focus + .switch-slider {
    box-shadow: 0 0 1px var(--coral);
}

input:checked + .switch-slider:before {
    -webkit-transform: translateX(25px);
    -ms-transform: translateX(25px);
    transform: translateX(25px);
}

/* Rounded sliders */
.switch-slider.round {
    border-radius: 34px;
}

.switch-slider.round:before {
    border-radius: 50%;
}

/* Switch slider YELLOW */

.switch-slider-yellow {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #D5D5D5;
    -webkit-transition: .4s;
    transition: .4s;
}

.switch-slider-yellow:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: var(--bone-light);
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .switch-slider-yellow {
    background-color: #FAE35B;
}

input:focus + .switch-slider-yellow {
    box-shadow: 0 0 1px var(--coral);
}

input:checked + .switch-slider-yellow:before {
    -webkit-transform: translateX(32px);
    -ms-transform: translateX(32px);
    transform: translateX(32px);
}

.switch-slider-yellow.round {
    border-radius: 34px;
}

.switch-slider-yellow.round:before {
    border-radius: 50%;
}

/* Switch slider RED */

.switch-slider-red {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #D5D5D5;
    -webkit-transition: .4s;
    transition: .4s;
}

.switch-slider-red:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: var(--bone-light);
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .switch-slider-red {
    background-color: #FF3569;
}

input:focus + .switch-slider-red {
    box-shadow: 0 0 1px var(--coral);
}

input:checked + .switch-slider-red:before {
    -webkit-transform: translateX(32px);
    -ms-transform: translateX(32px);
    transform: translateX(32px);
}

.switch-slider-red.round {
    border-radius: 34px;
}

.switch-slider-red.round:before {
    border-radius: 50%;
}

/* Switch slider GREEN */

.switch-slider-green {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #D5D5D5;
    -webkit-transition: .4s;
    transition: .4s;
}

.switch-slider-green:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: var(--bone-light);
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .switch-slider-green {
    background-color: #35FFC8;
}

input:focus + .switch-slider-green {
    box-shadow: 0 0 1px var(--coral);
}

input:checked + .switch-slider-green:before {
    -webkit-transform: translateX(32px);
    -ms-transform: translateX(32px);
    transform: translateX(32px);
}

.switch-slider-green.round {
    border-radius: 34px;
}

.switch-slider-green.round:before {
    border-radius: 50%;
}