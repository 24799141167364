.search-garages-main-image{
    margin-top: 10px;
    width: 200px;
}

.search-garages-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.search-garages-map{
    height: 83vh;
    width: 95%;
}

.search-garages-cards{
    width: 30%;
    overflow: auto;
    height: 700px;
    padding-right: 2%;
    padding-left: 2%;
}

.search-garages-map{
    width: 55%;
    height: 700px;
    margin-top: 20px;
}

.search-garages-info{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
}

.search-garages-card{
    width: 90%;
    border-radius: 10px;
    box-shadow: -2px 7px 13px 2px #d6d6d6;
    background-color: #fff;
    border: 1px solid #ced4da;
    padding: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.search-garages-card-p{
    font-size:15px;
    margin-bottom: 10px;
}

.search-garages-card-button{
    background-color: rgb(0, 0, 0);
    border-color: rgb(0, 0, 0);
    color: var(--coral);
    font-weight: bold;
    font-size: 13px;
    padding: 7px;
    border-radius: 10px;
}

.search-garages-card-button-wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.search-garages-card-position-wrapper{
    width: 40px;
    height: 40px;
    border: 1px solid black;
    background-color: white;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-content: center;
}

.search-garages-card-position{
    margin-top: 3px;
    font-size: 22px;
    font-weight: bold;
}

/* PORTATIL */
@media only screen and (max-width: 1500px){
    .search-garages-cards{
        width: 30%;
        overflow: auto;
        height: 500px;
        margin-right: 5%;
        border: pink;
    }

    .search-garages-map{
        width: 55%;
        height: 500px;
        margin-top: 20px;
    }
}