

.mapCard.card {
    width: 30%;
    max-width: unset;
    height: calc(100vh - 115px);
    margin: unset;
    border-radius: 20px;
    justify-content: start;
    position: sticky;
    top: 50px;
    box-shadow: rgb(0 0 0 / 30%) 0px 2px 11px 6px;
}
.mapContainer{
    width: 100%;
    height: 270px;
    border-radius: 20px;
    margin-bottom: 20px;
}
.mapContainer > div > div{
    border-radius: 20px;
}
.mapCard .titleMultiSelect{
    margin-top: 10px;
    margin-bottom: 10px;
    color: var(--black-wolly);
}
.multiSelectContainer{
    width: 85%;
    margin-left: auto;
    margin-right:auto ;
}
.dateRangeTitle{
    margin-top: 5px;
    margin-bottom: 10px;
    color: var(--black-wolly);
}
.datePromt{
    background-color: var(--white);
    border: solid 1px var(--black-wolly);
    border-radius: 7px;
    padding: 8px 10px;
    width: 100%;
    text-align: center;
}
.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover,.CalendarDay__default:hover,.CalendarDay__hovered_span,.CalendarDay__hovered_span:hover{
    background: var(--new-gold);
    border: 1px double var(--new-gold);
    color: var(--black-wolly);

}
.CalendarDay__selected_span{
    background: var(--new-gold);
    border: 1px double var(--new-gold);
    color: var(--black-wolly);
}
.rangeDatePickerContainer{
    margin-left: auto;
    margin-right:auto ;
    width: 85%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
}
.rangeDatePickerContainer > .DayPicker {
    background: #fff;
    position: absolute;
    text-align: left;
    top: 80px;
    z-index: 500;
}
.checkBoxes{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    width: 85%;
    margin: 20px auto 0;
    display: flex;
}
.checkBoxes .titleCheckbox{
    width: 100%;
    color: var(--black-wolly);
    margin-bottom: 10px;
}
.checkBoxes label input{
    margin-left: 5px;
    width: 20px;
    height: 20px;
}

@media only screen and (max-width: 1570px) {
   .rangeDatePickerContainer, .multiSelectContainer {
        width: 95%;
    }
    .checkBoxes{
        
        width: 95%;
        
    }
}
@media only screen and (max-width: 576px) {
    /* phone */
    .mapCard{
        display: none;
    }
}
