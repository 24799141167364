.garage-search-input{
    padding: 15px;
    height: 48px;
    border-radius: 10px;
    box-shadow: -2px 7px 13px 2px #d6d6d6;
    background-color: #fff;
    border: 1px solid #ced4da;
    width: 1000px;
}

textarea:focus, input:focus{
    outline: none;
}