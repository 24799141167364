.inputText{
    outline: none;
    border: none; 
    background-color: #f2f2f2; 
    padding: 7px 0px 7px 10px;
    margin-right: 15px;
    color: #444444;
    border-radius: 4px;
    font-size: 16px;
    font-family:"Source", sans-serif;
}

.inputText::placeholder {
    color: #b0b0b0;
}