.businessDetailColumn {
    flex: 1;
    height: calc(100vh - 143px);
    overflow-y: auto;
    flex-direction: column;
    border-right: 2px solid var(--soft-grey);
    position: relative;
}
.nearJobsDetailColumn {
    flex: 1;
    height: calc(100vh - 250px);
    flex-direction: row;
    border-right: 2px solid var(--soft-grey);
    position: relative;
}
.match-filter-slider-wrapper{
    display: flex;
    justify-content: space-between;
    padding: 15px 50px;
}

.near-job-check{
    padding-top: 20px   ;
    margin-bottom: -10px;  
    margin-right: 20px;
    width: 25px;
}

.near-job-check-two{
    width: 25px;
}

.near-job-card{
    width: 95%;
    border: 1px solid rgb(210, 209, 209);
    height: 200px;
    margin: 0px 2.5%;
    margin-top: 16px;
    border-radius: 20px;
    box-shadow: 0px 11px 10px 1px var(--soft-grey);
    padding: 15px;
}

.near-job-card-lead{
    color: rgb(53, 67, 255);
    font-weight: bold;
    margin-right: 10px;
    border: 1px solid rgb(53, 67, 255);
    border-radius: 5px;
    padding: 1px 2.5px;
    height: 25px;
}

.near-job-card-type{
    text-transform: uppercase;
    margin-right: 50px;
    color: rgb(53, 67, 255);
    font-weight: bold;
}

.f-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.f-row.margin-top {
    margin-top: 10px;
}

.f-row-left {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 8px;
    width: 100%;
}

.title-f-row{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.near-job-card-more-info{
    background-color: white;
    border: none;
    outline: none;
    align-self: center;
    margin-left: 40%;
    text-decoration: underline;
    font-weight: bold;
    margin-top: 10px;
}

.near-job-card-title{
    font-weight: bold;
}

.near-job-card-p{
    margin-top: 12px;
    align-items: center;
}

.near-jobs-list{
    flex: 1;
    overflow-y: auto;
    height: calc(100vh - 260px);
    flex-direction: column;
}

.near-job-card-buy{
    margin-top: 12px;
    background-color: black;
    font-weight: bold;
    color: #FFE235;
    border: none;
    padding: 10px 15px;
    border-radius: 10px;
}

.near-jobs-main-title{
    font-size: 22px;
    font-weight: bold;
    align-self: center;
    margin-top: 20px;
    margin-left: 30%;
}

.historyColumn {
    display: flex;
    flex: 1;
    height: calc(100vh - 143px);
    flex-direction: column;
    background-color: var(--bone-light);
    padding-right: 8px;
    padding-left: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
}
.historyContainer {
    margin-top: 16px;
    height: calc(100vh - 240px);
    overflow-y: auto;
}
.userHistoryContainer{
    margin-top: 16px;
    height: calc(45vh - 240px);
    overflow-y: auto;
}
.noteContainer {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 5px 5px 5px 0px var(--soft-grey);
    background-color: #fff;
    border: 1px solid var(--soft-grey);
}
.noteContainer .textInput {
    display: flex;
    border: none;
    height: 48px;
    padding-right: 8px;
    padding-left: 8px;
}
.noteContainer .textInput:focus {
    outline: none;
}
.noteContainer .buttonContainer {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}
.historyContainer .listContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.border-blue{
    border-color: rgb(53, 67, 255);
}
