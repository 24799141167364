.detailContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
}
.detailContainer .title {
    font-size: 18px;
    font-weight: 700;
}
.detailContainer .subTitle {
    font-size: 14px;
    font-weight: 600;
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0px 12px;
}

.fixedPhotos-title{
    font-size: 13px;
    color: var(--greyish);
    margin: 10px 0px 10px 0px;
}

.fixedPhotos-button{
    border: none;
    color: var(--black-wolly-darker);
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: 0px;
    opacity: 1;
    background-color: transparent;
}

.fixedPhotos-photo, .fixedPhotos-video {
    /*width: 150px;
    height: 150px;*/
    max-width: 100%;
    height: auto;
    border: 1px solid rgb(180, 180, 180);
}

.btn-see-details-modal {
    background-color: var(--soft-grey);
    color: var(--black-wolly);
    padding: 7px;
    border-radius: 4px;
    cursor: pointer;
    border: 0px;
    font-weight: bold;
}

.badge-total {
    background-color: #FFC41E;
    color: var(--white);
    text-align: center;
    height: 30px;
    width: 30px;
    line-height: 30px;
    border-radius: 50%;
    font-size: 12px;
    font-weight: 600;
}

.lm-photo-signature{
    width: 75px;
    height: 75px;
    border: 1px solid rgb(180, 180, 180);
    margin-top: 12px;
    padding: 5px;
}

.show-photos-modal-wrapper{
    height: 660px;
    width: 740px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.fixedPhotos-copy, .fixedPhotos-old-copy, .btn_asistentePresupuesto {
    font-weight: bold;
    border: 0px;
    background-color: var(--black-wolly);
    padding: 12px;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: 0px;
    opacity: 1;
}

.fixedPhotos-old-copy{
    background-color: var(--soft-grey);
    color: var(--black-wolly);
}

.btn_asistentePresupuesto {
    background: rgb(24,44,233);
    background:  linear-gradient(90deg, rgba(24,44,233,1) 0%, rgba(19,42,121,1) 58%);
    color: var(--white);
}

.lm-woller-p{
    font-weight: bold;
    margin-right: 150px;
}