.pdf-wrapper{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pdf-constructor{
    height: 1116px;
    width: 795px;
    padding-right: 48px;
    padding-left: 48px;
    border: 1px solid grey;
    margin-bottom: 20px;
}

.pdf-button{
    margin: 20px 0px;
    height: 36px;
    padding: 0px;
    width: 110px;
    background-color: rgb(0, 0, 0);
    border-color: rgb(0, 0, 0);
    color: #f2843b;
    border: 2px solid rgb(0, 0, 0);
    border-radius: 4px;
    font-family: Muli, sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
}

.pdf-constructor-logo{
    margin-top: 40px;
    margin-bottom: 10px;
    width: 120px;
}

.pdf-constructor-green-title{
    background-color: #262622;
    width: 170px;
    height: 40px;
    color: white;
    text-align: center;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    font-weight: bold;
    font-size: 14px;
    align-items: center;
    padding-top: 8px;
}

.pdf-constructor-white-title{
    border: 1px solid #262622;
    width: 170px;
    height: 40px;
    text-align: center;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    font-weight: bold;
    font-size: 14px;
    align-items: center;
    padding-top: 8px;
}

.pdf-constructor-white-big-title{
    border: 1px solid #262622;
    width: 530px;
    height: 40px;
    text-align: center;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    font-weight: bold;
    font-size: 14px;
    align-items: center;
    padding-top: 8px;
}

.pdf-constructor-parte{
    border: 1px solid #262622;
    color: #262622;
    width: 340px;
    height: 40px;
    text-align: center;
    border-radius: 10px;
    font-weight: bold;
    padding-top: 6px;
    font-size: 16px;
}

.mr-20{
    margin-right: 20px;
}

.pdf-constructor-box-wrapper{
    display: flex;
    flex-direction: row;
    margin-top: 15px;
}

.pdf-constructor-images-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.pdf-constructor-photo-service-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}

.pdf-constructor-big-green-title{
    background-color: #262622;
    width: 700px;
    height: 40px;
    color: white;
    text-align: center;
    border-radius: 10px;
    font-weight: bold;
    font-size: 16px;
    align-items: center;
    padding-top: 8px;
    margin-top: 20px;
}

.pdf-constructor-white-ultra-title{
    border: 1px solid #262622;
    width: 700px;
    height: 160px;
    text-align: center;
    border-radius: 10px;
    font-size: 12px;
    align-items: center;
    padding-top: 8px;
    margin-top: 10px;
    padding: 10px;
}

.pdf-constructor-client-wrapper{
    border: 1px solid #262622;
    width: 700px;
    height: 100px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: row;
}

.pdf-constructor-woller-wrapper{
    border: 1px solid #262622;
    width: 700px;
    height: 110px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.pdf-constructor-little-p{
    font-size: 10px;
    width: 570px;
    padding: 10px;
}

.pdf-constructor-footer-bold-p{
    font-size: 10px;
    text-decoration: underline;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 4px;
}

.pdf-constructor-footer-p{
    font-size: 10px;
}

.pdf-photo-service {
    width: 35em;
    border: 0.1em solid rgb(180, 180, 180);
    margin-top: 1.5em;
    padding: 0.2em;
}