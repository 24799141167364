.maps-filter-modal-title{
    font-weight: bold;
    margin-top: 5px;
    margin-right: 35px;
    font-size: 18px;
    width: 90px;
}

.maps-filter-medium-modal-title{
    font-weight: bold;
    margin: 5px 35px 0px 15px;
    font-size: 18px;
    width: 90px;
}

.mr-20{
    margin-right: 20px;
}

.mr-36{
    margin-right: 36px;
}

.maps-filter-modal-wrapper{
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.maps-filter-modal-row{
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    width: 100%;
    align-items: center;
}

.basic-multi-select{
    width: 635px;
}

.maps-filter-modal-button{
    border: 0px;
    background-color: black;
    padding: 14px;
    width: 100px;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
    align-self: center;
    margin-top: 35px;
    margin-left: 330px;
}

.maps-filter-datepicker{
    width: 635px;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    font-family: "Source", sans-serif;
    font-size: 16px;
    font-weight: normal;
    color: var(--greyish-brown);
    line-height: 1.5;
    height: 40px;
    text-align: center;
}

@media only screen and (max-width: 576px) {

    .maps-filter-modal-wrapper{
        flex-direction: column;
    }

    .basic-multi-select{
        width: 335px;
    }

    .maps-filter-datepicker{
        width: 335px;
    }

    .maps-filter-modal-button{
        margin: 0px;
    }

    .maps-filter-modal-title{
        width: 190px;
        text-align: center;
        margin-bottom: 10px;
    }

    .maps-filter-medium-modal-title{
        text-align: center;
        margin-bottom: 10px;
    }
}