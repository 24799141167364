.user-wrapper{
    width: 100%;
    height: 460px;
    margin-top: 20px;
    overflow-y: scroll;
}

.match-search-input{
    width: 370px;
    height: 40px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.5;
    padding: 12px 12px 12px 20px;
    background-color: rgb(242, 242, 242);
    appearance: none;
    border: 1px solid transparent;
    color: rgb(68, 68, 68);
    font-family: Source, sans-serif;
    margin-right: 10px;
    font-weight: bold;
    margin-top: 25px;
    outline: none;
}

.match-filters{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.jobs-match-icon{
    width: 13px;
    height: 13px;
    margin-right: 10px;
}

.match-filter-wrapper{
    margin-top: 10px;
}
.match-filter-wrapper-constructor{
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.match-filter-wrapper-constructor .labedCheckbox{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.match-filter-wrapper-constructor .labedCheckbox input{
    margin-left: 10px;
}


.woller-match-icon{
    height: 56px;
    width: 56px;
    border-radius: 28px;
    overflow: hidden;
    object-fit: cover;
}

.woller-match-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--soft-grey);
    padding: 12px;
}
.woller-match-wrapper-noPromo{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--soft-grey);
    padding: 12px;
    background: var(--soft-grey);
}

.medal-image{
    margin-left: -15px;
}

.woller-match-name{
    font-weight: bold;
}

.woller-match-details{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.woller-match-button-blue{
    width: 100px;
    height: 40px;
    border: 0px;
    background-color: rgb(53, 255, 200);
    border-radius: 4px;
    color: rgb(255, 255, 255);
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: 0px;
    opacity: 1;
    font-weight: bold;
}

.woller-match-button-red{
    width: 100px;
    height: 40px;
    border: 0px;
    background-color: rgb(255, 53, 105);
    border-radius: 4px;
    color: rgb(255, 255, 255);
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: 0px;
    opacity: 1;
    font-weight: bold;
}

.match-filter-title{
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 12px;
}

.match-filter-select-title{
    font-weight: bold;
    font-size: 12px;
}

.match-filter-slider{
    margin-top: 10px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 30%;
}

.woller-match-loader{
    align-self: center;
    width: 75px;
    height: 75px;
    margin-top: 200px;
    margin-left: 350px;
}

.request-assistance-button {
    position: absolute;
    bottom: 20px;
    right: 200px;
    z-index: 1000;
}
