
@font-face {
    font-family: 'Hurme';
    src: url("../../assets/fonts/Hurme2.otf");
}

@font-face {
    font-family: 'HurmeBold';
    font-weight: "bold";
    src: url("../../assets/fonts/HurmeSuperBold.ttf");
}

.title{
    margin: auto;
    text-align: center;
}

.document-image{
    height: max-content;
    width: max-content;
    min-width: 600px;
    min-height: 510px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 40px;
}
.slider-container{
    width:600px;
    height:600px;
    margin:auto;
    margin-bottom: 15px;
}
.buttonsContainer{
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 15px;
}
.aprove,.reject{
    color: var(--white);
    padding: 15px 20px;
    font-size: 16px;
    border-radius: 10px;
    box-shadow:  0px 3px 13px -3px  var(--background-shadow);
    border: 0.5px solid var(--black-jobin);
}
.aprove{
    background-color: var(--kpis_green);
}
.reject{
    background-color: var(--kpis_red);

}