.multiSelectContainer:focus-visible{
    outline: none;
    height: 100px;
    width: 100%;
    position: relative;
}
.multiSelectContainer{
    width: 100%;
    position: relative;
    margin-top: 0.5vh;
}
.multiSelect{
    display: flex;
    position: relative;
    border: solid 1px var(--black-wolly);
    border-radius: 7px;
    width: 100%;
    padding: 10px 10px;
    justify-content: space-between;
    align-items: center;
}
.multiSelectIcon::after{
    content: '▼';
}
.multiSelectIcon.active::after{
    content: '▲';
}
.multiSelectIcon::after,.multiSelectIcon.active::after{
    height: 10px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.selectedOptions{
    height: 18px;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.optionList{
    display: none;
    position: absolute;
    z-index: 500;
    max-height: 150px;
    width: 100%;
    overflow-y: auto;
    top: 76px;
    left: 0;
    border: solid 1px var(--black-wolly);
}
.optionList.active{
    display: flex;
    flex-direction: column;

}
.option.selected{
    background-color: var(--black-wolly-darker);
    color: var(--white);
    border-bottom: solid 1px var(--black-wolly);
}
.option{
    border-bottom: solid 1px var(--greyish);
    padding: 12px 10px;
    background-color: var(--white);
}
@media only screen and (max-width: 1170px) {
    /* tablet */
    .optionList{
        display: none;
        position: absolute;
        z-index: 500;
        max-height: 130px;
        width: 100%;
        overflow-y: auto;
        top: 76px;
        left: 0;
        border: solid 1px var(--black-wolly);
    }
    .option{
        border-bottom: solid 1px var(--greyish);
        padding: 5px 10px;
        background-color: var(--white);
    }
}