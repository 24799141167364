.pdflm-wrapper{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pdf-lm{
    height: 1116px;
    width: 795px;
    padding-right: 48px;
    padding-left: 48px;
    border: 1px solid grey;
    margin-bottom: 20px;
}

.pdf-lm-button{
    margin: 20px 0px;
    height: 36px;
    padding: 0px;
    width: 110px;
    background-color: rgb(0, 0, 0);
    border-color: rgb(0, 0, 0);
    color: var(--coral);
    border: 2px solid rgb(0, 0, 0);
    border-radius: 4px;
    font-family: "Source", sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
}

.lm-logo{
    margin-top: 40px;
    margin-bottom: 10px;
    width: 120px;
}

.lm-green-title{
    background-color: #80b227;
    width: 170px;
    height: 40px;
    color: white;
    text-align: center;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    font-weight: bold;
    font-size: 14px;
    align-items: center;
    padding-top: 8px;
}

.lm-white-title{
    border: 1px solid #80b227;
    width: 170px;
    height: 40px;
    text-align: center;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    font-weight: bold;
    font-size: 14px;
    align-items: center;
    padding-top: 8px;
}

.lm-white-big-title{
    border: 1px solid #80b227;
    width: 530px;
    height: 40px;
    text-align: center;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    font-weight: bold;
    font-size: 14px;
    align-items: center;
    padding-top: 8px;
}

.lm-parte{
    border: 1px solid #80b227;
    color: #80b227;
    width: 340px;
    height: 40px;
    text-align: center;
    border-radius: 10px;
    font-weight: bold;
    padding-top: 6px;
    font-size: 16px;
}

.mr-20{
    margin-right: 20px;
}

.lm-box-wrapper{
    display: flex;
    flex-direction: row;
    margin-top: 15px;
}

.lm-images-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.lm-big-green-title{
    background-color: #80b227;
    width: 700px;
    height: 40px;
    color: white;
    text-align: center;
    border-radius: 10px;
    font-weight: bold;
    font-size: 16px;
    align-items: center;
    padding-top: 8px;
    margin-top: 20px;
}

.lm-white-ultra-title{
    border: 1px solid #80b227;
    width: 700px;
    height: 160px;
    text-align: center;
    border-radius: 10px;
    font-size: 12px;
    align-items: center;
    padding-top: 8px;
    margin-top: 10px;
    padding: 10px;
}

.lm-client-wrapper{
    border: 1px solid #80b227;
    width: 700px;
    height: 100px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: row;
}

.lm-woller-wrapper{
    border: 1px solid #80b227;
    width: 700px;
    height: 110px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.lm-little-p{
    font-size: 10px;
    width: 570px;
    padding: 10px;
}

.lm-footer-bold-p{
    font-size: 10px;
    text-decoration: underline;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 4px;
}

.lm-footer-p{
    font-size: 10px;
}