.jobs-maps-job-card{
    position: absolute;
    top: 11vh;
    left: 320px;
    height: 620px;
    width: 425px;
    background-color: white;
    margin: 10px 0px 0px -190px;
    border: 1px solid #b3b3b3;
    border-radius: 25px;
    box-shadow: 0px 11px 20px 1px rgba(130,130,130,0.67);
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px;
}

.job-card-info{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.job-card-title{
    margin-bottom: 20px;
    font-weight: bold;
}

.job-card-p{
    margin: 10px 0px;
}

.flex-row-space-beetween{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.job-card-asign-button{
    align-self: center;
    height: 45px;
    padding: 0px;
    width: 140px;
    background-color: rgb(0, 0, 0);
    border-color: rgb(0, 0, 0);
    color: white;
    border: 2px solid rgb(0, 0, 0);
    border-radius: 4px;
    font-family: "Source";
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    justify-content: center;
    margin: 0px 10px;
}

.job-card-asign-button-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.job-card-details-button{
    align-self: center;
    width: 150px;
    background: none;
    border: none;
    text-decoration: underline;
    font-weight: bold;
}

.job-card-details-button-todelete{
    align-self: center;
    width: 150px;
    background: none;
    border: none;
    text-decoration: underline;
    font-weight: bold;
    margin-top: 60px;
}

.job-card-cancel-button{
    background-color: transparent;
    border: none;
    font-weight: bold;
    padding: 0px;
    height: 10px;
    font-size: 18px;
}

.job-card-asign-button-modify{
    background-color: rgb(0, 0, 0);
    border-radius: 200px;
    width: 30px;
    height: 30px;
    border: none;
}

.job-card-asign-button-modify-p{
    color: white;
    font-size: 24px;
    font-weight: bold;
    margin-top: -3px;
}

.job-card-match-wrapper{
    display: flex;
    flex-direction: row;
}

.job-card-notification{
    margin-right: 20px;
    width: 25px;
}

.job-card-notifications{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0px;
}

.job-card-switch{
    margin-right: 20px;
}

.job-card-notifications-wrapper{
    width: 400px;
    display: flex;
    align-items: center;
}

.jobs-maps-woller-card{
    position: absolute;
    top: 11vh;
    left: 81%;
    height: 270px;
    width: 380px;
    background-color: white;
    margin: 10px 0px 0px -190px;
    border: 1px solid #b3b3b3;
    border-radius: 25px;
    box-shadow: 0px 11px 20px 1px rgba(130,130,130,0.67);
    z-index: 100;
    display: flex;
    flex-direction: column;
    padding: 25px;
}

.jobs-maps-woller-details{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.jobs-maps-woller-details-name{
    font-weight: bold;
    font-size: 18px;
    padding-left: 40px;
}

.jobs-maps-woller-details-phone{
    font-weight: bold;
    margin-bottom: 5px;
    padding-left: 40px;
}

.jobs-maps-woller-details-nJobs{
    padding-left: 40px;
}

.job-card-rechargue-button{
    align-self: center;
    height: 30px;
    padding: 0px;
    width: 80px;
    background-color: rgb(0, 0, 0);
    border-color: rgb(0, 0, 0);
    color: white;
    border: 2px solid rgb(0, 0, 0);
    border-radius: 4px;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    align-items: center;
    justify-content: center;
}

.jobs-maps-woller-details-medal{
    margin-bottom: -6px;
    width: 20px;
}

.job-card-woller-cancel-button{
    background-color: transparent;
    border: none;
    font-weight: bold;
    height: 20px;
    width: 20px;
    font-size: 18px;
    align-self: flex-end;
}

@media only screen and (max-width: 576px) {

    .jobs-maps-job-card{
        top: 13vh;
        left: 210px;
        height: 500px;
        width: 90%;
    }

}