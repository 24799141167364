.result-table-container {
    overflow-x: auto;
    padding: 16px;
    padding-bottom: 32px;
    position: relative;
    z-index: 0;
}
.result-table-container table {
    width: 100%;
    text-align: left;
}
.result-table-container table tbody tr:hover {
    background-color: var(--soft-grey);
}
.result-table-container table thead tr th {
    padding: 0 8px 8px;
}
.result-table-container table tbody tr{
    position: relative;
}
.result-table-container table tbody tr td {
    padding: 2px;
    max-width: 245px;
    overflow-x: hidden;
}
.logo-business>img{
    width: 40px;
    height: 40px;
}
.button-csv {
    padding: 8px;
    color: var(--coral);
    background: black;
    border-radius: 12px;
    font-weight: bold;
    margin-bottom: 15px;
}

.abierto-circle{
    background-color: green;
    outline: none;
    width: 35px;
    height: 35px;
    border-radius: 50px;
}

.in-progress-circle{
    background-color: yellow;
    outline: none;
    width: 35px;
    height: 35px;
    border-radius: 50px;
}

.cerrada-circle{
    background-color: red;
    outline: none;
    width: 35px;
    height: 35px;
    border-radius: 50px;
}

.controlOpsTable-header{
    width: 80%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.controlOpsTable-cell{
    width: 20%;
    border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.controlOpsTable-header-p{
    color: #FFE235;;
    font-size: 16px;
    font-weight: bold;
}

.controlOpsTable-table-p{
    color: white;
    font-size: 16px;
    font-weight: bold;
}

.controlOpsTable-title{
    color: white;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 30px;
}

.controlOpsTable-wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.bg-color-green{
    background-color: #093C44;
}

.bg-color-blue{
    background-color: #0D268E;
}

