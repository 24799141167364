.rangeContainer{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
}

/*********** Baseline, reset styles ***********/
 .rangeContainer input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 80%;
  }
  
  /* Removes default focus */
   .rangeContainer input[type="range"]:focus {
    outline: none;
  }
  
  /******** Chrome, Safari, Opera and Edge Chromium styles ********/
  /* slider track */
   .rangeContainer input[type="range"]::-webkit-slider-runnable-track {
    background-color: var(--aqua-jobin);
    border-radius: 0.5rem;
    height: 3px;
  }
  
  /* slider thumb */
   .rangeContainer input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -6.5px; /* Centers thumb on the track */
    background-color: var(--coral);
    border-radius: 0.5rem;
    height: 1rem;
    width: 1rem;
  }
  
   .rangeContainer input[type="range"]:focus::-webkit-slider-thumb {
    outline:none;
  }
  
  /*********** Firefox styles ***********/
  /* slider track */
   .rangeContainer input[type="range"]::-moz-range-track {
    background-color: var(--aqua-jobin);
    border-radius: 0.5rem;
    height: 3px;
  }
  
  /* slider thumb */
   .rangeContainer input[type="range"]::-moz-range-thumb {
    background-color: var(--coral);
    border: none; /*Removes extra border that FF applies*/
    border-radius: 0.5rem;
    height: 1rem;
    width: 1rem;
  }
  
   .rangeContainer input[type="range"]:focus::-moz-range-thumb{
    outline: 3px solid var(--coral);
    outline-offset: 0.125rem;
  }
  .rangeContainer .value{
    font-size: 18px;
  }