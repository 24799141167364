.breadcrumb {
    height: 40px;
    box-shadow: 0 1px 0 0 rgba(32, 32, 32, 0.1);
    background-color: #f8f7f5;
}
.breadcrumb ul {
    padding-left: 72px;
    list-style: none;
    margin: 0;
}
.breadcrumb ul li {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    line-height: 40px;
}

.breadcrumb ul li + li {
    padding-left: 30px;
}
.breadcrumb ul li + li::after {
    content: "";
    background-image: url("../../assets/breadcrumb-separator.svg");
    background-size: 12px 12px;
    background-repeat: no-repeat;
    width: 12px;
    height: 12px;
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translate(0, -50%);
}

.breadcrumb ul li a {
    text-decoration: none;
    font-size: 14px;
    color: var(--greyish);
}
.breadcrumb ul li a:hover {
    text-decoration: underline;
}
.breadcrumb ul li.active a {
    color: var(--greyish-brown);
}
