.search-input{
    width: 500px;
    height: 48px;
    border-radius: 4px;
    font-size: 16px;
    line-height: 1.5;
    padding: 12px 12px 12px 48px;
    background-color: rgb(242, 242, 242);
    appearance: none;
    border: 1px solid transparent;
    color: rgb(68, 68, 68);
    font-family: "Source", sans-serif;
    margin-right: 20px;
    font-weight: bold;
}