#service-steps {
    box-shadow: inset 1px 0 0 0 #e8e8e8;
    position: fixed;
    width: 16.667%;
    top: 192px;
    z-index: 2;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 0 24px;
}
#service-steps .step-item + .step-item {
    margin-top: 24px;
}
#service-steps p {
    color: var(--warm-grey);
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 0;
}
#service-steps p.title {
    margin-top: 8px;
    margin-bottom: 8px;
}