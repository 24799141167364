.jobType{
    text-align:center;
    padding: 2px 2px 2px 0px;
    margin: 2px 0px 2px 0px;
    border: 1px solid black;
    border-radius: 10px;
    border-color: #b0b0b0;
    width: 300px;
}

.deleteJobType{
    font-size: 12px;
    border: none;
    color: red;
    font-weight: bold;
    background-color: transparent;
}

.back-next-vertical-button{
    outline: none;
    background-color: black;
    color: white;
    border: 1px solid black;
    border-radius: 10px;
    padding: 5px 10px 5px 10px;
}

.woller-bussines-button{
    outline: none;
    background-color: black;
    color: white;
    border: 1px solid black;
    border-radius: 10px;
    padding: 10px 15px 10px 15px;
    margin-left: 50px;
}

.vertical-page{
    margin-top: 4px;
    font-weight: bold;
    color:black;
    font-size: 14px;
}