
.guild-data{
    display: flex;
    flex: 1;
    flex-direction: row;
}

.guild-data .guild-cell{
    display: flex;
    margin-right: 8px;
}

.guild-data .guild-cell .icon{
    height: 20px;
    width: 20px;
    margin-right: 8px;
}

.client-data{
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-left: 24px;
    padding-bottom: 24px;
    border-left: 1px solid var(--soft-grey);
}

.business-data .business-title{
    display: flex;
    flex: 1;
}

.business-data .business-cell{
    display: flex;
    flex: 1;
    height: 40px;
    align-items: center;
}

.business-data .business-cell img{
    height: 40px;
    width: 40px;
    background-color: var(--soft-grey);
    margin-right: 8px;
    border-radius: 20px;
    border: none;
    overflow: hidden;
    object-fit: cover;
}